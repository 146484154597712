import React, { useState } from 'react'
//import useMouse from 'react-typescript-hook-mouse'
import '../scss/MapContent.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useLastMapVersionQuery } from '../services/MapClientAPI'
import { useElementSize } from 'usehooks-ts'
import { GetEmptyDraft, GetEmptyViewport, ICoords, IDraftOptions, IDraftPosition } from '../features/map_draft/geometry'
import { IMapViewportState, defaultState} from '../features/map_draft/viewport'
import { FitViewport, getViewPort, Len_W2V, MoveViewport, Pos_D2V, Pos_D2W, Pos_V2D, Pos_V2W, ScaleViewPort } from '../services/math'
import { RootState } from '../store'
import CoordsBar from './elements/CoordsBar'
import { setViewport } from '../features/map_draft/viewport'
import useMouse from '@react-hook/mouse-position'
import MipMapDraftBitmap from './elements/MipMapDraftBitmap'
import MarkerLayer from './MarkerLayer'
//import MipMapDraftVector from './elements/MipMapDraftVector'
import MapBorderVector from './elements/MapBorderVector'
import ContourLayer from './ContourLayer'
//import DebugOverlay from './elements/DebugOverlay'
import {FormatCoordCont } from '../services/utils'
import { useParams } from 'react-router'
import { history } from '../store'

// проверка попадания метки в видимую часть карты
function isMapPosVisible(worldPt:ICoords, 
    draft_options:IDraftOptions):boolean
{   
    //с бекенда границы поступают в СК draft, нужно пересчитать в мировые
    var visibleLUw = Pos_D2W(draft_options, draft_options.draft_visible_lu)
    var visibleRDw = Pos_D2W(draft_options, draft_options.draft_visible_rd)
    if(worldPt.x < visibleLUw.x || worldPt.x > visibleRDw.x ||
        worldPt.y < visibleLUw.y || worldPt.y > visibleRDw.y) return false
    return true
}

/*
* функция проверяет, попадает ли переданная в url точка в
* допустимые видимые пределы карты, и корректен ли масштаб
*/
function isMapPosVisibleScale(worldPt:ICoords, trgScale:number,
    draft_options:IDraftOptions):boolean
{
    //возможно не подходит масштаб
    if(trgScale < draft_options.draft_min_scale 
        || trgScale > draft_options.draft_max_scale) return false
    
    return isMapPosVisible(worldPt, draft_options)
}




function MapContent(){
    const [mouseClickPos, setMouseClickPos] = useState({x: Infinity, y: Infinity})
    const [prevScaleDistance, setPrevScaleDistance] = useState(0)
    const [prevSingleTouch, setPrevSingleTouch] = useState({x: Infinity, y: Infinity})
    const [, setScaleCenter] = useState({x: Infinity, y: Infinity})

    const {data, error, isLoading} = useLastMapVersionQuery()
    
    const viewportStateStore:IMapViewportState = useSelector((state: RootState) => state.viewportState)
    var viewportState:IMapViewportState = viewportStateStore
    const dispatch = useDispatch()

    const {x, y, s} = useParams<{
        x?:string, 
        y?:string, 
        s?:string
    }>()

    const [outerViewportRef, { width, height }] = useElementSize()
    
    var viewport_size_px: ICoords= {
        x: width,
        y: height
    }

    const innerVireportRef = React.useRef(null)
    const mouseRaw = useMouse(innerVireportRef,{
        fps: 30,
        enterDelay: 100,
        leaveDelay: 100});
    
    /*
    * координаты мыши хранить в store не будем. будем использовать
    * от react-hook/mouse-position поскольку они в СК текущего элемента
    * для расчёта движения порта просмотра будем использовать
    * сдвиг между событиями мыши от самого события
    */ 
    var mouse: ICoords = {
        x: (mouseRaw != null ?? mouseRaw.x != null) ? mouseRaw.x! : 0,
        y: (mouseRaw != null ?? mouseRaw.y != null) ? mouseRaw.y! : 0
    }

    let world = {x: 0, y: 0}
    let draft = {x: 0, y: 0}
    var vp = GetEmptyViewport()

    var draft_options = GetEmptyDraft()
    if(!error && ! isLoading){
        draft_options = data!  

        
    if(viewportState.viewport_pos.wnd_world_scale === Infinity
        || viewportState.viewport_pos.wnd_world_zero.x === Infinity
        || viewportState.viewport_pos.wnd_world_zero.y === Infinity)
    {
        /*
        * никакого конкретного положения пока ещё нет в store
        * если задано положение в урле и оно корректно с точки
        * зрения значений, используем его
        * если нет, начальное положение по умолчанию
        */
        viewportState = defaultState
        if(x && y && s)
        {
            var userMarker:ICoords = {
                x: parseFloat(x!),
                y: parseFloat(y!)
            }
            var userScale = parseFloat(s!)
            if(!Number.isNaN(userMarker.x) && !Number.isNaN(userMarker.y)
                && !Number.isNaN(userScale))
            {
                if(isMapPosVisibleScale(userMarker, userScale, draft_options))
                {
                    /*
                    * положение порта просмотра - это расстояние от 0 мировой СК 
                    * до центра порта просмотра. чтобы получить координаты 0 мировой
                    * СК в СК порта просмотра нужно прибваить половину ширины порта просмотра
                    * 
                    * чтобы из точки в мировой СК получить новый viewport_pos, нужно
                    * проделать обратные операции
                    * 1. перевести точку в СК порта просмотра
                    * 2. отнять половину ширины порта просмотра
                    * 
                    * пересчёт СК напрямую с помощью функций сделать не получится, т.к.
                    * для этого нужно положение порта просмотра, а его 
                    * как раз и нужно получить
                    * 
                    * поэтому проще сделять прямой расчёт через длину.
                    * для расчётов длины достаточно масштаба из url
                    */
                    var rawWorldPos = {
                        x: userMarker.x,
                        y: userMarker.y
                    }
                    //в функции пересчёта длины используется только wnd_world_scale
                    var substDO:IDraftPosition =
                    {
                        wnd_world_zero: { x: 0, y: 0},
                        wnd_world_scale: 1.0 / userScale
                    }
                    /*
                    * переданные координаты - расстояние в мировой СК от мирового 0
                    * до мирового центра. нужно перевести расстояние в СК порта
                    * просмотра на основе мастшаба
                    */    
                    var viewWorldLen:ICoords = {
                        x: Len_W2V(rawWorldPos.x, substDO),
                        y: Len_W2V(rawWorldPos.y, substDO),
                    }
                    viewportState = {
                        viewport_pos: {
                        wnd_world_zero:{
                            x: -viewWorldLen.x,
                            y: -viewWorldLen.y
                        },
                        wnd_world_scale: 1.0 / userScale
                            }
                    }  
                }
            }
        }
    }

        
        world = Pos_V2W(mouse, viewportState.viewport_pos, viewport_size_px)
        draft = Pos_V2D(draft_options, mouse, viewportState.viewport_pos, viewport_size_px)
    
        /*
        * посчитаем порт просмотра
        * на вход поступает:
        * 1) положение (0, 0) мировой СК относительно центра элемента
        * 2) текущий масштаб отображения (количество метров в пикселе окна)
        * 3) размер порта просмотра (размер элемента)
        */
        vp = getViewPort(draft_options, viewportState.viewport_pos, viewport_size_px);
    }

   
    // расчёт положения и масштаба фона с учётом положения порта просмотра
    var bgSize: ICoords = {
        x: 1,
        y: 1
    }
    // коэффициент увеличения фона. для возможности двигать
    var bgMoveCoef = 1.0
    /*
    * в зависимости от того что больше, ширина порта просмотра или высота
    * соотношение сторон фонового изображения известно
    */
    var bgRatioX = 21.0
    var bgRatioY = 9.0
    /*
    * числа в background-size должны:
    * 1. быть не меньше размеров блока
    * 2. их соотношение должно быть равно соотношению сторон фона
    */
    if(bgRatioY * viewport_size_px.x > viewport_size_px.y * bgRatioX)
    {
        bgSize.x = viewport_size_px.x 
        bgSize.y = viewport_size_px.x * bgRatioY / bgRatioX
    }
    else
    {
        bgSize.x = viewport_size_px.y * bgRatioX / bgRatioY
        bgSize.y = viewport_size_px.y 
    }
    //теперь нужно слегка растянуть, чтобы было куда двигать фон
    bgSize.x *= bgMoveCoef
    bgSize.y *= bgMoveCoef
    /*
    * размер рассчитан. положение фона будет определяться положением центра порта просмотра
    * относительно видимой части карты
    */
    var viewportCenter: ICoords = {
        x: viewport_size_px.x / 2,
        y: viewport_size_px.y / 2
    }
    var visibleLU = Pos_D2V(draft_options, draft_options.draft_visible_lu, 
        viewportState.viewport_pos, viewport_size_px)
    var visibleRD = Pos_D2V(draft_options, draft_options.draft_visible_rd, 
        viewportState.viewport_pos, viewport_size_px)
    var bgPosX = (viewportCenter.x - visibleLU.x) / (visibleRD.x - visibleLU.x) * 100
    var bgPosY = (viewportCenter.y - visibleLU.y) / (visibleRD.y - visibleLU.y) * 100

    var bgLimitLow = 30
    var bgLimitHigh = 70

    if(bgPosX < 0.0) bgPosX = 0
    if(bgPosX > 100.0) bgPosX = 100
    if(bgPosY < 0.0) bgPosY = 0
    if(bgPosY > 100.0) bgPosY = 100

    bgPosX = bgLimitLow + (bgPosX / 100.0) * (bgLimitHigh - bgLimitLow)
    bgPosY = bgLimitLow + (bgPosY / 100.0) * (bgLimitHigh - bgLimitLow)

    var bgUrl = process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/background/bg_m.jpg'
    var innerStyle={
        backgroundImage: 'url("' + bgUrl + '")',
        backgroundSize: bgSize.x + 'px ' + bgSize.y + 'px',
        backgroundPosition: bgPosX + '% '+ bgPosY + '%'
    }
    // var debugLines:string[] = [
    //     'mouse x: ' + FormatCoord(mouse.x),
    //     'mouse y: ' + FormatCoord(mouse.y),
    // ]
    
    return(
    <div className='interactive-map__content--outer' ref={outerViewportRef}>
        <div className='interactive-map__content--inner' ref={innerVireportRef}
        style={innerStyle}
        onMouseDown={(mouse_event)=>{
            setMouseClickPos(mouse)
        }}
        onMouseUp={(mouse_event)=>{
            /*
            * нужно отличать нажатия от перемещения карты мышью
            * способ достаточно простой - расстояние между
            * положением мыши когда кнопку нажали и когда отпустили
            */
            if(((mouseClickPos.x - mouse.x) * (mouseClickPos.x - mouse.x) +
               (mouseClickPos.y - mouse.y) * (mouseClickPos.y - mouse.y)) < 100.0)
            {
                /*
                * этот же код используется при мобильных касаниях
                * долгое касание эмулирует нажатие мышью
                * в прочие моменты координаты будут нулевые
                */
                if(mouse.x > 0 && mouse.y > 0)
                {
                    //не позволяем ставить метки за пределами видмой части карты
                    if(isMapPosVisible(world, draft_options))
                    {
                        history.push("/pos/"+ FormatCoordCont(world.x) + "=" + FormatCoordCont(world.y) +
                        "=" + FormatCoordCont(1.0 / viewportState.viewport_pos.wnd_world_scale))
                        dispatch(setViewport(FitViewport(viewportState.viewport_pos,
                            viewport_size_px, draft_options, null)))
                    }
                }
            }
        }}
        onMouseMove={(mouse_event)=>{
            /*
            * координаты мыши будем брать от react-hook/mouse-position, а
            * их изменение от события. изменение не зависит от
            * конкретной системы координат
            */ 
            if(mouse_event.buttons === 1)
            {   
                if(mouse_event.movementX !== 0 || mouse_event.movementY !== 0)
                {
                    dispatch(setViewport(FitViewport(MoveViewport(
                        viewport_size_px, 
                        draft_options,
                        viewportState.viewport_pos, {
                        x: mouse_event.movementX,
                        y: mouse_event.movementY
                    }),viewport_size_px, draft_options, null)))
                }
            }
        }}
        onWheel={(wheel_event)=>{
            if(wheel_event.deltaY !== 0)
            {
                var newDraftPos:IDraftPosition = ScaleViewPort(
                    draft_options,
                    viewportState.viewport_pos,
                    mouse,
                    viewport_size_px,
                    wheel_event.deltaY < 0 ? 0.95: 1.05)
                dispatch(setViewport(FitViewport(newDraftPos, 
                    viewport_size_px, draft_options, mouse)))
            }
        }} onTouchStart={(event:React.TouchEvent<HTMLDivElement>)=>{
            if(event.touches.length > 0)
            {
                setPrevSingleTouch({x: event.touches[0].screenX, 
                    y: event.touches[0].screenY})
            }
        }}
          onTouchEnd={(event:React.TouchEvent<HTMLDivElement>)=>{
            // обработка аналогична движению карты мышью
            // может этот код пригодится ещё
            // if(event.touches.length == 1)
            // {
            //     var touchEnd:ICoords = {
            //         x: event.touches[0].screenX,
            //         y: event.touches[0].screenY
            //     }
            //     if(((prevSingleTouch.x - touchEnd.x) * (prevSingleTouch.x - touchEnd.x) +
            //     (prevSingleTouch.y - touchEnd.y) * (prevSingleTouch.y - touchEnd.y)) < 100.0)
            //     {
            //         //придётся пересчитать координаты касания в мировую СК
            //         var touchView:ICoords = {
            //             x: event.touches[0].pageY,
            //             y: event.touches[0].pageY
            //         }
            //         var touchWorld = Pos_V2W(touchView, viewportState.viewport_pos, viewport_size_px)
            //         if(isMapPosVisible(touchWorld, draft_options))
            //         {
            //             // history.push("/pos/"+ FormatCoordCont(touchView.x) 
            //             // + "=" + FormatCoordCont(touchView.y) +
            //             // "=" + FormatCoordCont(1.0 / viewportState.viewport_pos.wnd_world_scale))
            //             // dispatch(setViewport(FitViewport(viewportState.viewport_pos,
            //             //     viewport_size_px, draft_options, null)))
            //         }
            //         //setPrevSingleTouch(touchEnd)
            //     }         
            // }          
            setPrevSingleTouch({x: Infinity, y: Infinity})
        }} onTouchMove={(event:React.TouchEvent<HTMLDivElement>)=>{
            if(event.touches.length === 1)
            {
                if(prevSingleTouch.x !== Infinity && prevSingleTouch.y !== Infinity)
                {
                    //какие координаты использовать не важно, всё равно используется разница
                    var moveDelta:ICoords = {
                        x: event.touches[0].screenX - prevSingleTouch.x,
                        y: event.touches[0].screenY - prevSingleTouch.y,
                    }
                    //setPrevSingleMovevent(moveDelta)
                    setPrevSingleTouch({x: event.touches[0].screenX, 
                        y: event.touches[0].screenY})
                    
                        dispatch(setViewport(FitViewport(MoveViewport(
                                viewport_size_px, 
                                draft_options,
                                viewportState.viewport_pos, {
                                x: moveDelta.x,
                                y: moveDelta.y
                            }),viewport_size_px, draft_options, null)))
                }
                setPrevScaleDistance(Infinity)
                setScaleCenter({x: Infinity, y: Infinity})
            }
            else
            {
                if(event.touches.length === 2)
                {
                    /*
                    * Масштабирование. Масштабировать будем относительно
                    * точки посередине между первыми двумя касаниями
                    * 
                    * Масштаб будем увеличивать на фиксированную величину
                    * в зависимости от того, увеличилось или уменьшилось
                    * расстояние между точками касания
                    */
                    var p1:ICoords = {
                        x: event.touches[0].pageX,
                        y: event.touches[0].pageY,
                    }
                    var p2:ICoords = {
                        x: event.touches[1].pageX,
                        y: event.touches[1].pageY,
                    }
                    // квардатный корень извлекать не требуется, используется только для сравнения
                    var scaleDist = (p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y)
                    
                    setScaleCenter({
                        x: (p1.x + p2.x) / 2,
                        y: (p1.y + p2.y) / 2
                    })
                    if(prevScaleDistance !== Infinity)
                    {
                        /*
                        * вводим определённый порог для исключения
                        * погрешностей при прикосновении пальцами
                        */
                        if(Math.abs(scaleDist - prevScaleDistance) > prevScaleDistance * 0.05)
                        {
                            var newDraftPos:IDraftPosition = ScaleViewPort(
                                draft_options,
                                viewportState.viewport_pos,
                                {
                                    x: (p1.x + p2.x) / 2,
                                    y: (p1.y + p2.y) / 2
                                },
                                viewport_size_px,
                                prevScaleDistance < scaleDist ? 0.95: 1.05)
                            dispatch(setViewport(FitViewport(newDraftPos, 
                                viewport_size_px, draft_options, mouse)))
                                setPrevScaleDistance(scaleDist)
                        }
                        else
                        {
                             setPrevScaleDistance(prevScaleDistance)
                        }
                    }
                    else
                    {
                        setPrevScaleDistance(scaleDist)
                    }
                }
                else
                {
                    setPrevScaleDistance(Infinity)
                    setScaleCenter({x: Infinity, y: Infinity})
                }
                setPrevSingleTouch({x: Infinity, y: Infinity})
            }
        }}>        
            {/* <DebugOverlay lines={debugLines}/>  */}
            <MapBorderVector vp={vp}
                viewport_size_px={viewport_size_px}
                draft_options={draft_options}
                draft_pos={viewportState.viewport_pos}
            />
            {/* используем 2 слоя для ячеек карты. один с заглушками, 
                другой с настоящими фрагментами
                нижний слой должен отображаться если
                для верхнего картинки ещё не загрузились */}
            <MipMapDraftBitmap vp={vp} 
                viewport_size_px={viewport_size_px}
                draft_options={draft_options}
                draft_pos={viewportState.viewport_pos}
                subst_img={true}
                />
            <MipMapDraftBitmap vp={vp} 
                viewport_size_px={viewport_size_px}
                draft_options={draft_options}
                draft_pos={viewportState.viewport_pos}
                subst_img={false}
                />
            {/* <MipMapDraftVector vp={vp} 
                viewport_size_px={viewport_size_px}
                draft_options={draft_options}
                draft_pos={viewportState.viewport_pos}
                /> */}
            <ContourLayer vp={vp} 
                viewport_size_px={viewport_size_px}
                draft_options={draft_options}
                draft_pos={viewportState.viewport_pos}/>
            <MarkerLayer vp={vp} 
                viewport_size_px={viewport_size_px}
                draft_options={draft_options}
                draft_pos={viewportState.viewport_pos}/>
            <CoordsBar isLoading={isLoading} isError={error !== undefined}
                screen_pos={mouse}
                world_pos={world} draft_pos={draft}
                viewport_pos={viewportState.viewport_pos}           
            />
        </div>       
    </div>)

}

export default MapContent