import { ICoords, IDraftPosition } from '../../features/map_draft/geometry'
import { FormatCoord, Num2Str } from '../../services/utils'
import { GetCurScale } from '../../services/math'


export interface ICoordsBarProps {
    viewport_pos: IDraftPosition,
    screen_pos: ICoords,
    world_pos: ICoords,
    draft_pos: ICoords,
    isLoading: boolean,
    isError: boolean
} 

const CoordsBar = ({viewport_pos, screen_pos, world_pos, 
    draft_pos, isError, isLoading}:ICoordsBarProps) =>
{
    let coords_string = 'V=(' + FormatCoord(screen_pos.x)+'px,'+ FormatCoord(screen_pos.y) + 'px)' 
        +
        ' W=(' + FormatCoord(world_pos.x)+'м,'+ FormatCoord(world_pos.y) + 'м)'
        +
        ' D=(' + FormatCoord(draft_pos.x)+'мм,'+ FormatCoord(draft_pos.y) + 'мм)'
        +
        ' S='+ Num2Str(GetCurScale(viewport_pos), 2)
    let mob_str = '(' + FormatCoord(world_pos.x)+' '+ FormatCoord(world_pos.y) + ') S=' 
    + Num2Str(GetCurScale(viewport_pos), 2)

    return(<div className="coords"><span className="reg">{coords_string}</span>
    <span className="mob">{mob_str}</span></div>);
}
export default CoordsBar
