// строка с локализацией
export interface I18NString {
    ru: string,
    en: string
}

/*
* для формирования ссылки на иконку из спрайта
* тип элемента
*/

export enum eIconType {
    MapObjectType = "map_obj_type",
    MapMarker = "map_marker_type",
    MapMenuBg = "menu_bg"
}