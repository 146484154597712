import { ICoords, IDraftOptions, IDraftPosition, IViewPort } from "../features/map_draft/geometry"
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { useMapObjectsQuery } from "../services/MapClientAPI"
import { IMapMarker } from "../features/mapMarker"
import MapMarker from "./elements/MapMarker"
import { Pos_W2V } from "../services/math"
import { useParams } from "react-router"


export interface IMarkerLayerProps{
    vp:IViewPort, 
    viewport_size_px: ICoords,
    draft_options: IDraftOptions,
    draft_pos: IDraftPosition
}

function isMarkerVisible(marker_pos:ICoords, draft_position: IDraftPosition,
    viewport_size: ICoords):boolean
{
    var view_pos = Pos_W2V(marker_pos, draft_position, viewport_size)
    return (view_pos.x >= 0) && (view_pos.y >= 0) &&
    (view_pos.x <= viewport_size.x) &&(view_pos.y <= viewport_size.y)
}

function isVisibleAtDay(item:IMapMarker, selected_day: number)
{
    /*
    * Метка видна если:
    * 1) Отмеченая как видимая в любой день day == 0
    * 2) Выбранный день совпадает с текущим днём 
    * 3) День задан, не выбран, элемент отмечен как всегда видимый
    */ 
    return (item.marker_day === 0 && item.always_visible === true) || 
    (selected_day === item.marker_day)
    ||(item.marker_day !== 0 && item.always_visible === true && selected_day === 0)
}
 
const MarkerLayer = ({vp, viewport_size_px, draft_options,
    draft_pos}:IMarkerLayerProps) => {

    const {x, y, } = useParams<{
        x?:string, 
        y?:string, 
        s?:string
    }>()
    /*
    * может быть заданы параметры url для отображения пользовательского маркера
    */
    var userMarker:ICoords = {
        x: NaN,
        y: NaN
    }
    if(x && y)
    {
        userMarker = {
            x: parseFloat(x!),
            y: parseFloat(y!)
        }
    }
    const disabled_types = useSelector((state: RootState) => state.selectedMapObjectTypes.disabled_ids)
    const displayMode = useSelector((state:RootState) => state.displayMode)

    const {data, error, isLoading} = useMapObjectsQuery()
    if(error || isLoading)
    {
        return <div className="marker-layer"></div>
    }
    else
    {
        var all_markers:IMapMarker[] = data!
        return <div className="marker-layer">
            {all_markers.filter((item) =>{
                return !disabled_types.includes(item.type_id) &&
                isMarkerVisible(item.world_pos, draft_pos, viewport_size_px) &&
                isVisibleAtDay(item, displayMode.selected_day)
            }).map((item:IMapMarker) =>(
           <MapMarker key={item.id} item={item} 
                draft_position={draft_pos}
                viewport_size={viewport_size_px}/>))}
            {!Number.isNaN(userMarker.x) && !Number.isNaN(userMarker.y) && (
                <MapMarker key={0} item={{
                    id: 0,
                    world_pos: {
                        x: userMarker.x,
                        y: userMarker.y
                    },
                    sprite_id: "type_icon_pos",
                    type_id: 0,
                    always_visible: true,
                    marker_day: 0
                }} 
                draft_position={draft_pos}
                viewport_size={viewport_size_px}/>
            )}
        </div>
    }
}

export default MarkerLayer