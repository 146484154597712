import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDisplayMOde {
    selected_day:  number,
    selected_lang: string
}

const initialState = {
    selected_day: 0,
    selected_lang: 'inv',
    menu_open: false
}

export const displayMode = createSlice({
    name: 'displayMode',
    initialState,
    reducers:{
            switchSelectedDay: (state, action:PayloadAction<number>) =>{
                if(state.selected_day === action.payload)
                {
                    state.selected_day = 0
                }
                else
                {
                    state.selected_day = action.payload
                }
            },
            setLanguage: (state, action:PayloadAction<string>) =>{
                state.selected_lang = action.payload
            },
            switchMenuMode: (state, action:PayloadAction<number>) =>{
                if(state.menu_open === true){
                    state.menu_open = false
                }else{
                    state.menu_open = true
                }
            }
        }
    })

    export const { switchSelectedDay, setLanguage, switchMenuMode } = displayMode.actions
    export default displayMode.reducer