import '../scss/MapMenu.scss'
import { RootState } from '../store'
import { useSelector } from 'react-redux'
import { useMapObjectTypesQuery, useMapSettingsQuery } from '../services/MapClientAPI'
import { getSpriteLink, printMultilangString } from '../services/utils'
import { eIconType, I18NString } from '../features/common'
//import { switchObjectType } from '../features/selectedObjectType'
//import { IMapObjType } from '../features/mapObjectType'
import MapCalendar from './elements/Calendar'
import ModeSelect from './elements/ModeSelect'
//import { useEffect, useState } from 'react';
import MapMarkerFilter from './elements/MapMarkerFilter'
//import { useParams } from 'react-router'

function MapMenu() { 

    const {/*data,*/ /*error,*/ isLoading} = useMapObjectTypesQuery()
    const {data:dataLang, /*error,*/ isLoading: isLoadingLang} = useMapSettingsQuery()
    const displayMode = useSelector((state:RootState) => state.displayMode)
    const router = useSelector((state:RootState) => state.router)
    console.log(router)

    var loadingMsg:I18NString = {
        ru: "Загрузка...",
        en: "Loading..."
    }   
    if(isLoading || isLoadingLang){
        // здесь язык с сервера неизвестен, используем по умолчанию
        return (<div className='interactive-map__menu'>
            <h1>{printMultilangString(loadingMsg, 'en')}</h1>
        </div>)
    }
    else{
        /*
        * если пользователь явно выбрал язык, используем его
        * иначе используем язык с сервера
        * 
        * определяем язык на уровне меню и передаём
        * потребителям в props.
        * Q: может переделать с помощью createAsyncThunk? 
        */
        var activeLang = displayMode.selected_lang
        if(activeLang === 'inv' && dataLang)
        {
            activeLang = dataLang?.lang
        }

        var bgContentStyle = 
        {
            backgroundImage: 'url("' + getSpriteLink(eIconType.MapMenuBg, 'map_menu_bg') + '")'
        }
    var mapTitle:I18NString = {
        ru: "Карта Города",
        en: "Town Map"
    }    
    return (
        <div className='interactive-map__menu'>
            <ModeSelect activeLang={activeLang}/>
            <div className='interactive-map__menu--content' style={bgContentStyle}>
                <h1>{printMultilangString(mapTitle, activeLang)}</h1>
                <MapCalendar selected_day={displayMode.selected_day} mobile_mode={false}/>
                <MapMarkerFilter activeLang={activeLang}/>
            </div>
            <div className='interactive-map__menu--padding' style={bgContentStyle}>
            </div>      
        </div>
    )
    }
}

export default MapMenu;