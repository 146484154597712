import './scss/InteractiveMap.scss'
import MapMenu from './components/MapMenu';
import MapContent from './components/MapContent';
import MapMenuMobile from './components/MapMenuMobile';

function InteractiveMap() {
    return (
        <div className="interactive-map">
            <MapMenu/>
            <MapMenuMobile/>
            <MapContent/>
        </div>
    )
}

export default InteractiveMap;