import { useAllScenariosQuery } from "../../services/MapClientAPI"
import { RootState } from "../../store"
import { useSelector, useDispatch } from 'react-redux'
import { getSpriteLink, printMultilangString } from "../../services/utils"
import { setLanguage } from "../../features/displayMode"
import { eIconType, I18NString } from "../../features/common"
import { IGameScenario } from "../../features/map_draft/api_entities"

export interface IModelSelectMobileProps
{
    activeLang: string
}

const ModeSelectMobile = ({activeLang}:IModelSelectMobileProps) =>{

    const {data, /*error,*/ isLoading} = useAllScenariosQuery()
    const displayMode = useSelector((state:RootState) => state.displayMode)
    const dispatch = useDispatch()
     
    if(isLoading){
        return (<div></div>)
    }
    var dayTitle:I18NString =
    {
        ru: "День ",
        en: "Day "
    }
    
    return <div className="map-mode-moblie">
        <div className={activeLang==='ru' ? 'maplang--active': 'maplang'}
            onClick={() => dispatch(setLanguage('ru'))}>
                <img src={getSpriteLink(eIconType.MapMenuBg,'lang_ru')} alt="lang"/>
            </div>
            <div className={activeLang==='en' ? 'maplang--active': 'maplang'}
            onClick={() => dispatch(setLanguage('en'))}>
                <img src={getSpriteLink(eIconType.MapMenuBg,'lang_en')} alt="lang"/>
            </div>
            {data?.map((item:IGameScenario)=>{
                return(<div className={item.id === 1 ? 'scenario-item--active':'scenario-item'} 
                key={item.id}>{printMultilangString(item.title, activeLang)[0]}</div>)
            })}
            {displayMode.selected_day > 0 && (
                <span>{printMultilangString(dayTitle, activeLang) +
                    displayMode.selected_day}</span>
            )}
    </div>
}

export default ModeSelectMobile