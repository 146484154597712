import React from 'react';
import ReactDOM from 'react-dom';

import {Provider }from 'react-redux'
import './index.css';
import InteractiveMap from './InteractiveMap';
import { store, history } from './store'
import { ConnectedRouter } from 'connected-react-router' 
import { Route, Switch } from 'react-router-dom'
import QuestContainer from './components/quest/QuestContainer';

const NotFoundComponent = () =>{
  return <div><h1>Url not found</h1><a href="/">Start Page</a></div>
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <>
        <Switch>
          <Route exact path="/" component={InteractiveMap}/>
          <Route path="/pos/:x=:y=:s" component={InteractiveMap}/>
          <Route path="*" component={NotFoundComponent}/>
          <Route path="/somethingspecial" component={QuestContainer}/>
        </Switch>
      </>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
