import { ICoords } from "../../features/map_draft/geometry"


export interface IMapContourProps {
    view_pos:ICoords,
    view_size:ICoords,
    viewbox_size: ICoords, 
    svg_path: string,
    contour_type: number,
    contour_kind: number
}
function getSvgFill(type:number, kind:number):string{
    // 0 - сбор трав
    if(kind === 0){
        return "#617d3e77"
    }
    // 1 - заражённый район
    if(kind === 1){
        return "#9e381d66"
    }
    // 2 - район после заражения
    if(kind === 2){
        return "#5050507f"
    }
    // 3 - район может быть заражён
    if(kind === 3){
        return "#9b972773"
    }
    // 4 - после заражения, когда мог быть заражён
    if(kind === 4)
    {
        return "#26210273" 
    } 
    return "#ffffff55"
}

function getSvgStroke(type:number, kind:number):string{
    // 0 - сбор трав
    if(kind === 0){
        return "#4b523377"
    }
    // 1 - заражённый район
    if(kind === 1){
        return "#44180d66"
    }
    // 2 - район после заражения
    if(kind === 2){
        return "#67676789"
    }
    // 3 - район может быть заражён
    if(kind === 3){
        return "#e59419e5"
    }  
    // 4 - после заражения, когда мог быть заражён
    if(kind === 4)
    {
        return "#9b972773"
    }
    return "#ffffff55"
}

const MapContour = ({
    view_pos, view_size, viewbox_size, 
    svg_path, contour_type, contour_kind
}:IMapContourProps) => {
    var viewBoxStr = "0 0 " + viewbox_size.x + " " + viewbox_size.y 
        return (<div className="contour" style={{
                left: view_pos.x,
                top: view_pos.y,
                width: view_size.x,
                height: view_size.y
            }}>
            <svg width={view_size.x} height={view_size.y} 
            viewBox={viewBoxStr} xmlns="http://www.w3.org/2000/svg" style={
                {
                    fill: getSvgFill(contour_type, contour_kind),
                    stroke: getSvgStroke(contour_type, contour_kind),
                    strokeWidth: 0.5,
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeOpacity: 1
                }
            }><path d={svg_path}/></svg>
            </div>)
}

export default MapContour