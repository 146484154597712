import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useElementSize } from "usehooks-ts"
import { eIconType } from "../../features/common"
import { IMapObjType } from "../../features/mapObjectType"
import { ICoords } from "../../features/map_draft/geometry"
import { switchObjectType } from "../../features/selectedObjectType"
import { useMapObjectTypesQuery } from "../../services/MapClientAPI"
import { getSpriteLink, printMultilangString } from "../../services/utils"
import { RootState } from "../../store"

export interface IMapMarkerFilterProps
{
    activeLang: string
}


const MapMarkerFilter = ({activeLang}:IMapMarkerFilterProps) => {
    
    const [scrollPos, setScrollPos] = useState(0);
    const [prevListTouch, setPrevListTouch] = useState({x: Infinity, y: Infinity})
    const {data, /*error,*/ /*isLoading*/} = useMapObjectTypesQuery()
    const dispatch = useDispatch()
    const disabled_types = useSelector((state: RootState) => state.selectedMapObjectTypes.disabled_ids)
    
    //const displayMode = useSelector((state:RootState) => state.displayMode)

    const [scrollContainerRef, outerSize] = useElementSize()
    const [scrollContentRef, innerSize] = useElementSize()

    // это высота индикатора положения прокрутки, расчёт из пропорции
    var scrollHeight = outerSize.height * outerSize.height / innerSize.height 
    /*
    * положение индикатора прокрутки нужно задавать
    * относительно прокручиваемого содержимого
    * меняется оно от 0 для прокрутки на 0% до
    * innerSize.height - scrollHeight для прокрутки на 100%
    */
    if(scrollHeight > outerSize.height) scrollHeight = 0
    var markerPos = scrollPos * (outerSize.height / innerSize.height)

    return (<div className="dis-scroll interactive-map__menu--type-container" ref={scrollContainerRef} onWheel={(mouse_event: React.WheelEvent<HTMLDivElement>) => {
        var newScrollPos = scrollPos + ((mouse_event.deltaY) > 0 ? 15 : -15)
        if(newScrollPos < 0) newScrollPos = 0
        if(newScrollPos > (innerSize.height - outerSize.height))
        {
            newScrollPos = (innerSize.height - outerSize.height)
        }
        setScrollPos(newScrollPos);
    }} onDragStart={()=>{return false}} 
    onTouchStart={(event:React.TouchEvent<HTMLDivElement>)=>{
        if(event.touches.length > 0)
        {
            setPrevListTouch({x: event.touches[0].screenX, 
                y: event.touches[0].screenY})
        }
    }}
    onTouchEnd={(event:React.TouchEvent<HTMLDivElement>)=>{
        setPrevListTouch({x: Infinity, y: Infinity})
    }}
    onTouchMove={(event:React.TouchEvent<HTMLDivElement>)=>{
        if(event.touches.length > 0)
        {
            var moveDelta:ICoords = {
                x: event.touches[0].screenX - prevListTouch.x,
                y: event.touches[0].screenY - prevListTouch.y,
            }
            setPrevListTouch({x: event.touches[0].screenX, 
                y: event.touches[0].screenY})

            var newScrollPos = scrollPos - moveDelta.y
            if(newScrollPos < 0) newScrollPos = 0
            if(newScrollPos > (innerSize.height - outerSize.height))
            {
                newScrollPos = (innerSize.height - outerSize.height)
            }
            setScrollPos(newScrollPos);
        }
    }}>
    <div className="type-markers" ref={scrollContentRef} style={{
        top: -scrollPos
    }} onDragStart={()=>{return false}}>
        <ul>
        {data?.map((item:IMapObjType)=>(<li key={item.id}>
            <div className={disabled_types.includes(item.id) ? 'menu-item--disabled': 'menu-item'} 
            onClick={() => dispatch(switchObjectType(item.id))}>
                <img className='menu-item__check' src={getSpriteLink(eIconType.MapMenuBg, disabled_types.includes(item.id) ? 'item_unchecked': 'item_checked')} alt="menu_item"/>
                <img className='menu-item__icon' src={getSpriteLink(eIconType.MapObjectType, item.sprite_id)}  alt="menu_item"/>
                <span className='menu-item__title'>{printMultilangString(item.title, activeLang)}</span>
            </div>
        </li>))}
        </ul>      
    </div>
    {(scrollHeight > 0) && <div className="custom-scrollmarker" style={
        {
           height: scrollHeight,
           top: markerPos
        }} onMouseMove={(mouse_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if(mouse_event.buttons === 1 && mouse_event.movementY !== 0)
            {
                var coef = (outerSize.height / innerSize.height)
                var newPos = scrollPos + mouse_event.movementY / coef;
                if((newPos >= 0) && (newPos <= (innerSize.height - outerSize.height)))
                {
                    setScrollPos(newPos);
                }
            }
        }}>

    </div>}
    </div>)
}

export default MapMarkerFilter