import { FormatCoord } from "../../services/utils";

export interface ICoords{
    x: number;
    y: number;
}

//прямоугольник, заданный левым верхним и правым нижним углом
export interface IRect{
    lu: ICoords,
    rd: ICoords
}

//структура для расчёта viewBox svg
export interface ISvgViewBox{
    lu: ICoords,
    size: ICoords,
    pos_delta: ICoords,
    size_delta: ICoords
}

//тип границы: внутренняя, вертикальная граница, горизонтальная граница
export enum eCellBorderType {
    Internal = "cell_internal",
    BrdLeft = "brd_left",
    BrdRight = "brd_right",
    BrdTop = "brd_top",
    BrdBottom = "brd_bottom"
}

//данные о положении порта просмотра (W --> V)
export interface IDraftPosition{
    //положение мирового нуля относительно ЦЕНТРА окна, в пикселях
    wnd_world_zero: ICoords;
    //масштаб оконной ск - сколько метров в пикселе окна
    wnd_world_scale: number;
}

//данные для отображения, в оконной СК
export interface IViewPort{
    //левый верхний угол порта просмотра
    wnd_lu_pos: ICoords;
    //правый нижний угол порта просмотра
    wnd_rd_pos: ICoords;
    //аналогично для контейнера с картой
    cnt_lu_pos: ICoords;
    cnt_rd_pos: ICoords;
}

export function GetEmptyViewport():IViewPort
{
    return {
        wnd_lu_pos: {x:0, y:0},
        wnd_rd_pos: {x:0, y:0},
        cnt_lu_pos: {x:0, y:0},
        cnt_rd_pos: {x:0, y:0}
    }
}

export interface IMipMapParams
{
    mipmap_max_level:number,
    mipmap_zero_level_m: number,
    mipmap_zero_level_n: number
}

/*
* данные картинки для карты (без привязки к порту просмотра)
* все размеры с префиксом draft в мм
*/
export interface IDraftOptions{
    //положение (0,0) мировой СК в СК карты (мм)
    draft_world_zero: ICoords;
    //масштаб карты - сколько метров в мм карты
    draft_scale: number;
    //вся карта целиком, кратно m * n квадратов 
    draft_rect_lu: ICoords;
    draft_rect_rd: ICoords;
    //вложенный прямоугольник, который будет виден
    draft_visible_lu: ICoords;
    draft_visible_rd: ICoords;
    //размеры карты во внутренних единицах
    draft_size: ICoords;
    /*
    * прямоугольник, который нельзя 
    * выпускать за пределы порта просмотра
    */
    draft_limit: IRect,
    /*
    * ограничения на масштаб,
    * сверху и снизу. масштаб в px на метр
    */
    draft_min_scale:number,
    draft_max_scale:number,
    /*
    * количество разбиений карты на уровни, включительно
    * 0 - верхний из m * n квадратов
    * 1 - каждый квадрат первого уровня делится на 4 квадрата 
    * 2 - каждый квадрат первого уровня делится на 4 квадрата
    * 3 - и т.д.
    */
    draft_mipmap:IMipMapParams
}

export function GetEmptyDraft():IDraftOptions{
    return {
        draft_world_zero: {x: 0, y: 0},
        draft_scale: 1.0,
        draft_rect_lu: {x: 0, y: 0},
        draft_rect_rd: {x: 0, y: 0},
        draft_visible_lu: {x: 0, y: 0},
        draft_visible_rd: {x: 0, y: 0},
        draft_limit: {
            lu: {x: 0, y: 0},
            rd: {x: 0, y: 0},
        },
        draft_size: {x: 0, y: 0},
        draft_min_scale: 1.0,
        draft_max_scale: 1.0,
        draft_mipmap: {
            mipmap_max_level: 1,
            mipmap_zero_level_m: 0,
            mipmap_zero_level_n: 0
        }
    }
}

/*
* Функция для расчёта viewBox для svg-элемента с картой
* на вход:
* - прямоугольник с всей картой, не важно в какой СК
* - прямоугольник с частью карты, подлежащей отображению. данные за его пределами не попадут во viewBox
* - прямоугольник, который будет отображаться в svg, в той же СК что и первый параметр
*   не обязательно вложен внутрь прямоугольника карты
* - внутренние размеры svg-файла
*
* на выход:
* - viewBox для элемента svg
*
*/
export function getSvgViewbox(map_rect_px:IRect,
                              map_vis_px: IRect,   
                              vp_rect_px:IRect,
                              svg_size:ICoords,
                              debug_lines: string[]): ISvgViewBox
{
    /*
    * в общем случае соотношение сторон svg-файла
    * и приёмного блока не будут совпадать
    * 
    * данный файл будет вписан в прямоугольник 
    * по центру с сохранением пропорций
    * нужно определить коэффициент для
    * пересчёта координат из пикселей во 
    * внутренние координаты svg и обратно
    * 
    * вариантов два: по соотношению ширин или
    * по соотношению высот
    */
    var mapSize:ICoords = {
        x: map_rect_px.rd.x - map_rect_px.lu.x,
        y: map_rect_px.rd.y - map_rect_px.lu.y
    }
    //debug_lines.push('map LU: (' + FormatCoord(map_rect_px.lu.x) + ',' + FormatCoord(map_rect_px.lu.y) + ')')
    //debug_lines.push('map RD: (' + FormatCoord(map_rect_px.rd.x) + ',' + FormatCoord(map_rect_px.rd.y) + ')')
   // debug_lines.push('map Size: (' + FormatCoord(mapSize.x) + ',' + FormatCoord(mapSize.y) + ')')
    var blockAspect = mapSize.x / mapSize.y
    var svgAspect = svg_size.x / svg_size.y
    var cPxToSvg = 0.0 //на этот коэффициент нужно умножить пиксели, чтобы получить svg
    var cSvgToPx = 0.0 //на этот коэффициент нужно умножить svg, чтобы получить пиксели
    if(svgAspect > blockAspect)
    {
        //пересчёт по ширине
        cSvgToPx = mapSize.x / svg_size.x
        cPxToSvg = 1.0 / cSvgToPx
    }
    else
    {
        //пересчёт по высоте
        cSvgToPx = mapSize.y / svg_size.y
        cPxToSvg = 1.0 / cSvgToPx
    }
    /*
    * положение svg стилями задано так, что левый верхний угол svg
    * приходится на левый верхний угол блока
    * 
    * вцелом нужно пересчитать координаты из СК карты в СК svg
    */ 
   // debug_lines.push('svg coef' + FormatCoord(cPxToSvg))
    var mapLU: ICoords = {
        x: map_rect_px.lu.x,
        y: map_rect_px.lu.y
    }
    //debug_lines.push('map Center: (' + FormatCoord(mapLU.x) + ',' + FormatCoord(mapLU.y) + ')')
    var svgLU: ICoords = {
        x: 0,
        y: 0
    }
    //debug_lines.push('svg Center: (' + FormatCoord(svgLU.x) + ',' + FormatCoord(svgLU.y) + ')')
    //отступы левого верхнего угла порта от цетра карты. они в пикселях
    var luDiffPx: ICoords = {
        x: vp_rect_px.lu.x - mapLU.x,
        y: vp_rect_px.lu.y - mapLU.y
    }
    //viewBox состоит из левого верхнего угла и размера. размер тоже нужно пересчитать
    var vpSize: ICoords = {
        x: vp_rect_px.rd.x - vp_rect_px.lu.x,
        y: vp_rect_px.rd.y - vp_rect_px.lu.y
    }

    //debug_lines.push('luDiffPx: (' + FormatCoord(luDiffPx.x) + ',' + FormatCoord(luDiffPx.y) + ')')
    //нужно пересчитать эти длины в единицы svg
    var luDiffSvg: ICoords = {
        x: luDiffPx.x * cPxToSvg,
        y: luDiffPx.y * cPxToSvg
    }
    var vpSizeSvg: ICoords = {
        x: vpSize.x * cPxToSvg,
        y: vpSize.y * cPxToSvg
    }
    //и применить к центру svg. получим первые два компонента viewBox. последние два это длины
    var res:ISvgViewBox = {
        lu:{ 
            x: svgLU.x + luDiffSvg.x, //откладываем от совпадающих центров на нужную длину
            y: svgLU.y + luDiffSvg.y
        },
        size: {
            x: vpSizeSvg.x, //тут нужны не координаты, а размер 
            y: vpSizeSvg.y
        },
        pos_delta: {
            x: 0,
            y: 0
        },
        size_delta: {
            x: 0,
            y: 0
        }
    }
    /*
    * полученный viewBox нужно подправить, чтобы не отображать невидимую часть файла
    * нужно посчитать левый угол и размеры
    */ 
    var visLUDiffPx: ICoords = {
        x: map_vis_px.lu.x - mapLU.x,
        y: map_vis_px.lu.y - mapLU.y
    }
    var visRDDiffPx: ICoords = {
        x: map_vis_px.rd.x - mapLU.x,
        y: map_vis_px.rd.y - mapLU.y
    }

    var visLuSvg: ICoords = {
        x: svgLU.x + visLUDiffPx.x * cPxToSvg,
        y: svgLU.y + visLUDiffPx.y * cPxToSvg
    }
    var visRDSvg: ICoords = {
        x: svgLU.x + visRDDiffPx.x * cPxToSvg,
        y: svgLU.y + visRDDiffPx.y * cPxToSvg
    }
    /*
    * сначала подвинем левый верхний угол
    * потом, если нужно, ширину и высоту
    */ 
    var diff_X_Svg = 0
    var diff_X_Px = 0

    var diff_Size_X_Svg = 0
    var diff_Size_X_Px = 0
    // если нужно, двигаем левый верхний угол и размер по горизонтали
    if(res.lu.x < visLuSvg.x)
    {
        diff_X_Svg = visLuSvg.x - res.lu.x
        diff_X_Px = diff_X_Svg * cSvgToPx
        // настолько двигается вперёд viewBox
        res.lu.x += diff_X_Svg
        // при этом ширину двигаем назад на столько же, чтобы она осталась на том же месте
        res.size.x -= diff_X_Svg
        res.pos_delta.x += diff_X_Px  
        res.size_delta.x -= diff_X_Px
        /*
        * изменяем viewBox так, чтобы невидимые части не отображались
        * сдвинули вправо левую границу, нужно подвинуть влево сам блок
        * при этом потребовалось скорректировать и ширину viewBox
        * чтобы масштаб отображения карты не изменился, пришлось
        * изменить и размер самого элемента svg в пикселях
        * 
        * diff для viewBox используется в единицах svg,
        * для размеров и положения блока в пикселях
        * обе поправки должны друг другу соответствовать
        */
    }
    // возможно размер по горизонтали нужно подправить тоже
    if(res.lu.x + res.size.x > visRDSvg.x)
    {
        /*
        * нужно помнить, что сюда может попасть
        * блок и viewBox с уже исправленной левой границей
        * 
        * res.lu.x и res.size.x правятся одновременно, и их сумма не меняется
        */
        diff_Size_X_Svg = res.lu.x + res.size.x - visRDSvg.x
        diff_Size_X_Px = diff_Size_X_Svg * cSvgToPx
        // поправка опять же должна касаться и размера в svg, и в пикселях
        res.size.x -= diff_Size_X_Svg
        res.size_delta.x -= diff_Size_X_Px
    }

    // аналогичный расчёт по вертикали
    var diff_Y_Svg = 0
    var diff_Y_Px = 0

    var diff_Size_Y_Svg = 0
    var diff_Size_Y_Px = 0

    if(res.lu.y < visLuSvg.y)
    {
        diff_Y_Svg = visLuSvg.y - res.lu.y
        diff_Y_Px = diff_Y_Svg * cSvgToPx
        res.lu.y += diff_Y_Svg
        res.size.y -= diff_Y_Svg
        res.pos_delta.y += diff_Y_Px  
        res.size_delta.y -= diff_Y_Px
    }

    if(res.lu.y + res.size.y > visRDSvg.y)
    {
        diff_Size_Y_Svg = res.lu.y + res.size.y - visRDSvg.y
        diff_Size_Y_Px = diff_Size_Y_Svg * cSvgToPx
        res.size.y -= diff_Size_Y_Svg
        res.size_delta.y -= diff_Size_Y_Px
    }

    debug_lines.push('_total_ map rect: ' + 
        'X0: ' + FormatCoord(map_rect_px.lu.x) + ' Y0: ' + FormatCoord(map_rect_px.lu.y) +
        ' W: ' + FormatCoord(map_rect_px.rd.x - map_rect_px.lu.x) + 
        ' H: ' + FormatCoord(map_rect_px.rd.y - map_rect_px.lu.y) +
        ' X1: ' + FormatCoord(map_rect_px.rd.x) + ' Y1: ' + FormatCoord(map_rect_px.rd.y))

    debug_lines.push('visible map rect: ' + 
        'X0: ' + FormatCoord(map_vis_px.lu.x) + ' Y0: ' + FormatCoord(map_vis_px.lu.y) +
        ' W: ' + FormatCoord(map_vis_px.rd.x - map_vis_px.lu.x) + 
        ' H: ' + FormatCoord(map_vis_px.rd.y - map_vis_px.lu.y) +
        ' X1: ' + FormatCoord(map_vis_px.rd.x) + ' Y1: ' + FormatCoord(map_vis_px.rd.y))
    debug_lines.push('-')

    debug_lines.push('internal svg size: W: ' + svg_size.x + ' H: ' + svg_size.y)
    debug_lines.push('viewbox for svg: ' + 
        'X0: ' + FormatCoord(res.lu.x) + ' Y0: ' + FormatCoord(res.lu.y) +
        ' W: ' + FormatCoord(res.size.x) + 
        ' H: ' + FormatCoord(res.size.y) +
        ' X1: ' + FormatCoord(res.lu.x + res.size.x) 
        + ' Y1: ' + FormatCoord(res.lu.y + res.size.y))
    // debug_lines.push('visLuSvg: (' + FormatCoord(visLuSvg.x) + ',' + FormatCoord(visLuSvg.y) + ')')
    // debug_lines.push('visRDSvg: (' + FormatCoord(visRDSvg.x) + ',' + FormatCoord(visRDSvg.y) + ')')
    // debug_lines.push('-')
    // debug_lines.push('viewbox LU: (' + FormatCoord(res.lu.x) + ',' + FormatCoord(res.lu.y) + ')')
    // debug_lines.push('viewbox HW: (' + FormatCoord(res.size.x) + ',' + FormatCoord(res.size.y) + ')')
    // debug_lines.push('-')
    // debug_lines.push('diff_X_Svg: (' + FormatCoord(diff_X_Svg) + ',' + FormatCoord(0) + ')')
    // debug_lines.push('diff_X_Px: (' + FormatCoord(diff_X_Px) + ',' + FormatCoord(0) + ')')
    // debug_lines.push('-')
    // debug_lines.push('diff_Size_X_Svg: (' + FormatCoord(diff_Size_X_Svg) + ',' + FormatCoord(0) + ')')
    // debug_lines.push('diff_Size_X_Px: (' + FormatCoord(diff_Size_X_Px) + ',' + FormatCoord(0) + ')')
    // debug_lines.push('-')
    // debug_lines.push('visSize X Y : (' + FormatCoord(visSizeX) + ',' + FormatCoord(0) + ')')
    return res
}