import { IMapContour } from "../features/map_draft/api_entities";
import { ICoords, IDraftOptions, IDraftPosition, IViewPort } from "../features/map_draft/geometry";
import { useSelector} from 'react-redux'
import { useMapContoursQuery } from "../services/MapClientAPI";
import { Pos_W2V } from "../services/math";
import { RootState } from "../store";
import MapContour from "./elements/MapContour";

export interface IContourLayerProps {
    vp:IViewPort, 
    viewport_size_px: ICoords,
    draft_options: IDraftOptions,
    draft_pos: IDraftPosition
}

//такой же контур, но в СК порта просмотра
interface IViewMapContour
{
    view_pos:ICoords;
    view_size:ICoords;
    viewbox_size:ICoords;
    contour_path:string;
    contour_kind:number;
    contour_type:number;
}

function isContourVisible(item:IViewMapContour, viewport_size: ICoords)
{
    if((item.view_pos.x > viewport_size.x) || (item.view_pos.y > viewport_size.y)) return false;
    if((item.view_pos.x + item.view_size.x < 0) || (item.view_pos.y + item.view_size.y < 0)) return false;
    return true;
}

function isContourVisibleAtDay(item:IMapContour, selected_day:number)
{
    /*
    * Контур виден если:
    * 1) Отмеченая как видимая в любой день day == 0
    * 2) Выбранный день совпадает с текущим днём 
    * 3) День задан, не выбран, элемент отмечен как всегда видимый
    */ 
    return (item.contour_day === 0 && item.always_visible === true) || 
    (selected_day === item.contour_day)
    ||(item.contour_day !== 0 && item.always_visible === true && selected_day === 0)
}


const ContourLayer = ({vp, viewport_size_px, draft_options,

    draft_pos}:IContourLayerProps) => {

        const disabled_types = useSelector((state: RootState) => state.selectedMapObjectTypes.disabled_ids)
        const displayMode = useSelector((state:RootState) => state.displayMode)

        const {data, error, isLoading} = useMapContoursQuery()
        if(error || isLoading)
        {
            return <div></div>
        }
        var allEmems:IMapContour[] = data!
        
        // код ниже нужен для тестирования отображения контуров
        // var elemLU:ICoords = {
        //     x: -4.5,
        //     y: 49.0
        // }

        // var svgSize = {
        //     x: 123.165,
        //     y: 112.171
        // }

        // var scale = 1.0

        // var elemSize:ICoords = {
        //     x: svgSize.x * scale,
        //     y: svgSize.y * scale
        // }

        // var sample:IMapContour =
        // {
        //     world_x: elemLU.x,
        //     world_y: elemLU.y,
        //     size_x: elemSize.x,
        //     size_y: elemSize.y,
        //     svg_width: svgSize.x,
        //     svg_height: svgSize.y,
        //     contour_day: 10,
        //     contour_kind: 1,
        //     contour_type: 11,
        //     always_visible: false,
        //     contour_path: "m 41.630379,0.6809747 c -1.7439,1.63285 -3.89,4.38346 -5.2297,6.49325 -1.3397,2.10979 -4.53536,6.4731403 -8.31356,10.7161903 -3.7782,4.24305 -10.1978,9.01309 -13.5584,11.59394 -3.3606,2.58086 -16.1609002,3.72748 -14.19190021,5.10141 1.96890001,1.37393 9.43440001,8.5365 12.01560021,14.28202 2.5812,5.74552 5.1311,10.73239 14.0327,23.16987 8.9016,12.43747 28.9932,25.19113 40.1699,29.406255 5.5884,2.10756 26.8575,9.43402 33.3164,10.19539 7.528601,0.88748 20.813501,0.16973 22.777201,-0.9382 2.6021,-1.46818 -8.91478,-10.923665 -7.5073,-16.778975 0.56578,-2.35374 -3.14302,-8.08832 -7.38884,-10.80764 -5.04183,-3.22914 -7.926931,-9.47187 -9.389931,-11.62222 -3.0906,-4.54264 -13.75541,-19.37751 -16.13263,-22.68259 -2.37722,-3.30508 -19.16579,-25.33952 -21.32392,-28.42557 -2.15812,-3.08606 -4.8384,-5.77378 -7.02926,-8.75838 -2.19086,-2.9846003 -3.84332,-5.7518403 -5.90045,-8.0058503 -2.05713,-2.25401 -4.60201,-4.57175 -6.34591,-2.9389 z"
        // }
        // allEmems = [sample]
        
        /*
        * сначала фильтруем по дню и типу, преобразуем координаты
        * в СК порта просмотра, потом фильтруем
        * по расположению внутри порта просмотра
        */
        return <div className="contour-layer">
            {allEmems.filter((item)=>{
                return !disabled_types.includes(item.contour_type) &&
                isContourVisibleAtDay(item, displayMode.selected_day)
            }).map((item, index) => {
                var elemLU:ICoords = Pos_W2V({
                    x: item.world_x,
                    y: item.world_y
                }, draft_pos, viewport_size_px)
        
                var elemRD:ICoords = Pos_W2V({
                    x: item.world_x + item.size_x,
                    y: item.world_y + item.size_y
                }, draft_pos, viewport_size_px)

                var sizePx:ICoords = {
                    x: elemRD.x - elemLU.x,
                    y: elemRD.y - elemLU.y
                }
                var viewRes:IViewMapContour = {
                    view_pos: elemLU,
                    view_size: sizePx,
                    viewbox_size: {x:item.svg_width, y:item.svg_height},
                    contour_path: item.contour_path,
                    contour_kind: item.contour_kind,
                    contour_type: item.contour_type
                }
                return viewRes
            }).filter((view_item) =>{
                return isContourVisible(view_item, viewport_size_px)
            }).map((view_item, index) =>{
                return <MapContour 
                key={index}
                view_pos={view_item.view_pos}
                view_size={view_item.view_size}
                viewbox_size={view_item.viewbox_size}
                svg_path={view_item.contour_path}
                contour_kind={view_item.contour_kind}
                contour_type={view_item.contour_type}
                />
            })
            }
        </div>
    }

export default ContourLayer

