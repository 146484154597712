import { eIconType } from "../../features/common"
import { eCellBorderType, IDraftOptions, IDraftPosition } from "../../features/map_draft/geometry"
import { getCellTypes, IMapFragment, Len_D2V } from "../../services/math"
import { getSpriteLink } from "../../services/utils"

export interface IMapCellProps
{
    item: IMapFragment
    cell_index: number
    draft_options: IDraftOptions
    draft_pos: IDraftPosition
    subst_img:boolean
}

function getCellStyle(item:IMapFragment, draft_options:IDraftOptions,
    dp: IDraftPosition, subst: boolean):any
{
    var res = {
        backgroundImage: `url('` +  (subst ? getSpriteLink(eIconType.MapObjectType, "cell_padding"): 
        getMapFragmentUrl(item.mipmap_path)) +`')`,
        backgroundSize: '100% 100%',
        left: item.left + 'px',
        top: item.top + 'px',
        width: item.width + 'px',
        height: item.height + 'px',
        backgroundPosition: '0px 0px'
    }
    var cell_types:eCellBorderType[] = getCellTypes(item.cell_pos, 
        draft_options.draft_mipmap, item.mipmap_level)
    if(cell_types.includes(eCellBorderType.Internal))
    {
        //коррекция не требуется
        return res
    }
    else
    {
        //отступы видимой части карты от грани файлов
        var dL = Len_D2V(draft_options, 
            draft_options.draft_rect_lu.x - draft_options.draft_visible_lu.x, dp)
        var dR = -Len_D2V(draft_options, 
            draft_options.draft_rect_rd.x - draft_options.draft_visible_rd.x, dp)
        var dT = Len_D2V(draft_options,
            draft_options.draft_rect_lu.y - draft_options.draft_visible_lu.y, dp)
        var dB = -Len_D2V(draft_options, 
            draft_options.draft_rect_rd.y - draft_options.draft_visible_rd.y, dp)

        var width = item.width
        var height = item.height
        var left = item.left
        var top = item.top
        var bgSizeX = 100
        var bgSizeY = 100
        var bgPosX = 0
        var bgPosY = 0
         //нужно изменить размеры и положение фона и ячейки
        if(cell_types.includes(eCellBorderType.BrdLeft))
        {         
            width = (item.width + dL)
            left = (item.left - dL)
            bgSizeX = (item.width / (item.width + dL) * 100)
            bgPosX = dL
        }
        if(cell_types.includes(eCellBorderType.BrdTop))
        {
            height = (item.height + dT)
            top = (item.top - dT)
            bgSizeY = (item.height / (item.height + dT) * 100)
            bgPosY = dT
        }
        if(cell_types.includes(eCellBorderType.BrdRight))
        {
            /*
            * положение подкручивать не нужно, достаточно размеров
            * учитываем также что width уже мог быть изменён
            */
            width = (width + dR)
            bgSizeX = (item.width / (width) * 100)
        }
        if(cell_types.includes(eCellBorderType.BrdBottom))
        {
            /*
            * положение подкручивать не нужно, достаточно размеров
            * учитываем также что height уже мог быть изменён
            */
            height = (height + dB)
            bgSizeY = (item.height / (height) * 100)
        }
        res.width = width + 'px'
        res.height = height + 'px'
        res.left = left + 'px'
        res.top =  top + 'px'
        res.backgroundSize =  bgSizeX + '% ' + bgSizeY + '%'
        res.backgroundPosition = bgPosX + 'px ' + bgPosY + 'px'
        return res
    }
}
function translatePathPart(part:string)
{
    switch(part)
    {
        case 'Q': return 'LU'
        case 'W': return 'RU'
        case 'A': return 'LD'
        case 'S': return 'RD'
    }
    return ''
}

function getMapFragmentUrl(mipmap_path:string):string
{
    var path:string[] = mipmap_path.split('-')
    var level = path.length - 1
    var res:string = process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/level' + level + '/cell'
    for(var l:number = 0; l <= level; l++)
    {
        var parts = path[l].split(':')
        if(l === 0)
        {
            res += '_' + parts[0] + '_' + parts[1]
        }
        else
        {
            res += '_' + translatePathPart(parts[0])
        }
    }
    res += '.svg'
    return res
}


const MapCell = ({ item, cell_index, draft_options, draft_pos,
    subst_img}:IMapCellProps) => {
    return <div className="internal--cell" key={cell_index}
    style={getCellStyle(item, draft_options, draft_pos, subst_img)}>
    </div>

}
export default MapCell