//import { url } from "inspector"
import { ICoords, IDraftOptions, IDraftPosition, IRect, IViewPort } from "../../features/map_draft/geometry"
import { viewportSubdivision } from "../../services/math"
import MapCell from "./MapCell"

export interface IMipMapDraftBitmapProps{
    vp:IViewPort, 
    viewport_size_px: ICoords,
    draft_options: IDraftOptions,
    draft_pos: IDraftPosition,
    subst_img: boolean
}
 


const MipMapDraftBitmap = ({vp, viewport_size_px, draft_options,
    draft_pos, subst_img}:IMipMapDraftBitmapProps) => {
    //размеры всей карты в пикселях
    var map_size: ICoords={
        x:vp.cnt_rd_pos.x - vp.cnt_lu_pos.x,
        y:vp.cnt_rd_pos.y - vp.cnt_lu_pos.y
    }
    //отступ видимого края карты от её начала (пока считаем 0)
    var lu_padding: ICoords={
        x: vp.cnt_lu_pos.x - vp.wnd_lu_pos.x,
        y: vp.cnt_lu_pos.y - vp.wnd_lu_pos.y
    }
    /*
    * это ширина карты в оконных координатах (в пикселях)
    * разница по высоте и по ширине между левым верхним
    * и правым нижним углом карты
    */
    var map_size_px: ICoords={
        x: vp.wnd_rd_pos.x - vp.wnd_lu_pos.x,
        y: vp.wnd_rd_pos.y - vp.wnd_lu_pos.y
    }
    var map_style={
        backgroundPositionX: lu_padding.x + 'px',
        backgroundPositionY: lu_padding.y + 'px',
        backgroundSize: map_size.x + 'px ' + map_size.y + 'px',
        left: Math.round(vp.wnd_lu_pos.x) + 'px',
        top: Math.round(vp.wnd_lu_pos.y) + 'px',
        width: Math.round(map_size_px.x) + 'px',
        height: Math.round(map_size_px.y) + 'px'
    }
    var wnd_map:IRect = {
        lu: vp.cnt_lu_pos,
        rd: vp.cnt_rd_pos
    }
    var wnd_viweport:IRect = {
        lu: {x:0, y: 0},
        rd: viewport_size_px 
    }

    var map_cells = viewportSubdivision(wnd_map, 
        wnd_viweport, draft_options.draft_mipmap)
    // http://localhost:3000/images/maps/1/level0/cell_0_0.svg
    // http://localhost:3000/images/maps/1/level1/cell_0_0_RD.svg
    // http://localhost:3000/images/maps/1/level2/cell_0_0_RU_LU.svg
    return(<div className="internal" style={map_style}>
            {map_cells.map((item, index)=>{
        return(<MapCell item={item} cell_index={index} key={index}
        draft_options={draft_options} draft_pos={draft_pos}
        subst_img={subst_img}/>)
    })}
    </div>)
}

export default MipMapDraftBitmap