import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IMapObjType } from '../features/mapObjectType'
import { IAPIMapContour, IAPIMapVersion, IGameScenario, IMapContour } from '../features/map_draft/api_entities' 
import { prepareViewportDesc } from '../services/math'
import { IDraftOptions } from '../features/map_draft/geometry'
import { IMapMarker } from '../features/mapMarker'
import { buildContourPath } from './utils'
import { IMapSettings } from '../features/mapSettings'

export const mapObjectsApi = createApi({
    reducerPath: 'mapObjects',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_MAP_API_ROOT + 'api/v1/'
    }),
    endpoints: (builder) =>({
        mapObjectTypes: builder.query<IMapObjType[], void>({
            query:() => 'map_objects/types/',
        }),
        lastMapVersion: builder.query<IDraftOptions, void>({
            query:() => 'map/last_version/',
            transformResponse: (response: IAPIMapVersion, meta, arg) =>{
                //здесь преобразуем версию карты в описание для рендера
                return prepareViewportDesc(response)
            }
        }),
        allScenarios: builder.query<IGameScenario[], void>({
            query:() => 'map/scenarios/',
        }),
        mapObjects: builder.query<IMapMarker[], void>({
            query:() => 'map_objects/markers/',
        }),
        mapContours: builder.query<IMapContour[], void>({
            query:() => 'map_objects/contours/',
            transformResponse: (response: IAPIMapContour[], meta, arg) =>{
                //точки контура преобразуем в строковый path, который передаётся в элемент svg
                return buildContourPath(response)
            }}),
        mapSettings: builder.query<IMapSettings, void>({
                query:() => 'map/settings/',
            })
    })
})

export const { useMapObjectTypesQuery, useLastMapVersionQuery,
        useMapObjectsQuery, useAllScenariosQuery, 
        useMapContoursQuery, useMapSettingsQuery } = mapObjectsApi