import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDraftPosition } from './geometry'

export interface IMapViewportState {
    viewport_pos: IDraftPosition,
}

export const defaultState: IMapViewportState = {
    viewport_pos: {
        wnd_world_zero:{
            x: 0.0,
            y: 0.0
        },
        wnd_world_scale: 0.5
    }
}

export const initialState: IMapViewportState = {
    viewport_pos: {
        wnd_world_zero:{
            x: Infinity,
            y: Infinity,
        },
        wnd_world_scale: Infinity
    }
}

export const viewportStateSlice = createSlice({
    name: 'viewport_state',
    initialState,
    reducers:{
        // moveViewport: (state, action: PayloadAction<ICoords>) => {
        //     state.viewport_pos.wnd_world_zero.x += action.payload.x
        //     state.viewport_pos.wnd_world_zero.y += action.payload.y
        // },
        setViewport: (state, action: PayloadAction<IDraftPosition>) => {
            state.viewport_pos = action.payload
        }
    }
}) 

export default viewportStateSlice.reducer
export const {setViewport} = viewportStateSlice.actions