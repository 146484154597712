import {IDraftPosition, ICoords, IViewPort, IDraftOptions, IMipMapParams, IRect, eCellBorderType} from '../features/map_draft/geometry'
import {IAPIMapVersion} from '../features/map_draft/api_entities'
//import {IMapViewportState} from '../features/map_draft/viewport'


/*
* функция определяет, является ли ячейка пограничной
* возвращается тип ячейки - внутренняя, вертикальная граница, горизонтальная граница
*/ 
export function getCellTypes(cell_pos:ICoords, mipmap:IMipMapParams, cur_level:number): eCellBorderType[]
{
    var res:eCellBorderType[] = []
    //определяем количество ячеек на переданном уровне
    var max_cells:ICoords = {
        x: mipmap.mipmap_zero_level_m * Math.pow(2, cur_level),
        y: mipmap.mipmap_zero_level_n * Math.pow(2, cur_level)
    }
    //0 или верхняя граница - ячейка пограничная
    if(cell_pos.x === 0)
    {
        res.push(eCellBorderType.BrdLeft)    
    }
    if(cell_pos.x === max_cells.x - 1)
    {
        res.push(eCellBorderType.BrdRight)
    }
    if(cell_pos.y === 0)
    {
        res.push(eCellBorderType.BrdTop)
    }
    if(cell_pos.y === max_cells.y - 1)
    {
        res.push(eCellBorderType.BrdBottom)
    }
    if(res.length === 0)
    {
        //ничего не подошло, значит внутренняя
        res.push(eCellBorderType.Internal)
    }
    return res
}


export function prepareViewportDesc(api_viewport: IAPIMapVersion): IDraftOptions
{
    /*
    * с бека приходят данные в мировой СК (в метрах)
    * и масштаб для пересчёта метров в мм
    * их нужно пересчитать в данные в системе карты (в мм)
    */ 
    var backend_world_lu = {
        x: api_viewport.border_west,
        y: api_viewport.border_north
    }
    var backend_world_rd = 
    {
        x: api_viewport.border_east,
        y: api_viewport.border_south
    }
    /*
    * количество мм на один метр карты
    * 4: карта 40 метров это 160 мм карты
    * 0.056753689: карта 1410 метров это 800 мм
    */
    var backend_scale_raw = api_viewport.world_scale

    /*
    * прямоугольник, за пределами которого данные
    * из файлов изображений
    * не будут показаны
    */
    var backend_brd_lu = {
        x: api_viewport.visible_west,
        y: api_viewport.visible_north
    }
    var backend_brd_rd = {
        x: api_viewport.visible_east,
        y: api_viewport.visible_south
    }

    //это отступы видимого прямоугольника от полного в мировых координатах
    var dL = backend_brd_lu.x - backend_world_lu.x
    var dR = backend_world_rd.x - backend_brd_rd.x

    var dT = backend_brd_lu.y - backend_world_lu.y
    var dB = backend_world_rd.y - backend_brd_rd.y

    //допускаем просмотр карты не более чем на 50мм за границами
    var draft_limit_padding = 50

    var backend_world_width = backend_world_rd.x - backend_world_lu.x
    var backend_world_height = backend_world_rd.y - backend_world_lu.y

    var backend_scale = 1.0 / backend_scale_raw
    //TODO отрефакторить пересчёт координат
    return {
        /*
        * CK самой карты расположена
        * в левом нижнем углу 
        * мировая СК использует координаты в метрах
        */
        draft_world_zero: {
            x: -backend_world_lu.x / backend_scale,
            y: -backend_world_lu.y / backend_scale, 
        },
        //позже будем получать с бека
        draft_scale: backend_scale,
        /*
        * прямоугольник, который подлежит отображению
        * нулевой уровень - это квадрат. 
        * первый уровень - m * n клеток. 
        * файлы изображений могут покрывать не всю карту
        * 
        * эти точки в мировой СК
        */
        draft_rect_lu: {x:0, y:0},
        draft_rect_rd: {x: backend_world_width / backend_scale, 
                        y: backend_world_height / backend_scale},
        draft_visible_lu: {
            x: (dL) / backend_scale, 
            y: (dT) / backend_scale 
        },
        draft_visible_rd: {
            x: (backend_world_width - dR) / backend_scale, 
            y: (backend_world_height - dB) / backend_scale
        },
        draft_size: {
            x: backend_world_width / backend_scale,
            y: backend_world_height / backend_scale
        },
        /*
        * прямоугольник, который нельзя выпускать за
        * границы порта просмотра. может быть как внутри
        * карты, так и снаружи. иметь больший размер
        * 
        * имеется в виду что хотя бы один угл этого
        * прямоугольника точно будет внутри порта просмотра
        * нельзя загнать карту далеко влево или вправо
        */
        draft_limit: { 
            lu: {
                    x: (dL - draft_limit_padding) / backend_scale, 
                    y: (dT - draft_limit_padding) / backend_scale
                },
            rd: {   x: (backend_world_width - dR + draft_limit_padding) / backend_scale,
                    y: (backend_world_height - dB + draft_limit_padding) / backend_scale
                }
        },
        //эти параметры лучше вынести в константы
        draft_min_scale: 1,
        draft_max_scale: 20,
        draft_mipmap: {
            mipmap_max_level: api_viewport.mipmap_levels,
            mipmap_zero_level_m: api_viewport.mipmap_level_m,
            mipmap_zero_level_n: api_viewport.mipmap_level_n
        }
    }       
}

/*
* расчёт порта просмотра
* на вход:
*   - положение порта просмотра в мировой СК
*   - размер порта просмотра
* на выход:
*   - как разместить картинку, в  СК чертежа
*/ 
export function getViewPort(draft_options: IDraftOptions, draft_pos:IDraftPosition, 
    viewport_size: ICoords) : IViewPort{
    /*
    * частью ответа на задачу будут
    * координаты левого верхнего угла карты в оконной СК      
    */
    var V_src_lu = Pos_D2V(draft_options, draft_options.draft_rect_lu, draft_pos, viewport_size)
    var V_src_rd = Pos_D2V(draft_options, draft_options.draft_rect_rd, draft_pos, viewport_size)

    var V_cnt_lu = Pos_D2V(draft_options, {x: 0, y: 0}, draft_pos, viewport_size)
    var V_cnt_rd = Pos_D2V(draft_options, draft_options.draft_size, draft_pos, viewport_size)
    
    var res: IViewPort ={
        wnd_lu_pos: V_src_lu,
        wnd_rd_pos: V_src_rd,
        cnt_lu_pos: V_cnt_lu,
        cnt_rd_pos: V_cnt_rd
    }
    return res
}
//Перевод координат точки D --> W
export function Pos_D2W(draft_options: IDraftOptions, src :ICoords):ICoords{
    var p = draft_options 
    return {
        x: Len_D2W(draft_options, src.x - p.draft_world_zero.x),
        y: Len_D2W(draft_options, src.y - p.draft_world_zero.y)
    }
}
//Перевод координат точки W --> D
export function Pos_W2D(draft_options: IDraftOptions, src :ICoords):ICoords{
    var p = draft_options 
    return {
        x: p.draft_world_zero.x + Len_W2D(draft_options, src.x),
        y: p.draft_world_zero.y + Len_W2D(draft_options, src.y),
    }
}
//Перевод координат точки V --> W
export function Pos_V2W(src :ICoords, dp: IDraftPosition, vs: ICoords):ICoords{
    return{
        x: Len_V2W(src.x - vs.x / 2 - dp.wnd_world_zero.x, dp),
        y: Len_V2W(src.y - vs.y / 2 - dp.wnd_world_zero.y, dp)
    }
}
    //Перевод координат точки W --> V
export function Pos_W2V(src :ICoords, dp: IDraftPosition, vs: ICoords):ICoords{
    return{
        x: dp.wnd_world_zero.x + vs.x / 2 + Len_W2V(src.x, dp), 
        y: dp.wnd_world_zero.y + vs.y / 2 + Len_W2V(src.y, dp)
    }
}

//Перевод координат точки D --> V
export function Pos_D2V(draft_options: IDraftOptions, src :ICoords, 
    dp: IDraftPosition, vs: ICoords):ICoords{
    return Pos_W2V(Pos_D2W(draft_options, src), dp, vs)
}
//Перевод координат точки V --> D
export function Pos_V2D(draft_options: IDraftOptions, src :ICoords,
    dp: IDraftPosition, vs: ICoords):ICoords{
    return Pos_W2D(draft_options, Pos_V2W(src, dp, vs))
}
//Перевод расстояния W --> D
export function Len_W2D(draft_options: IDraftOptions, len:number):number{
    //len - это длина в метрах. нужно получить эквивалентную длину в пикселях
    return len / draft_options.draft_scale
}
//Перевод расстояния D --> W
export function Len_D2W(draft_options: IDraftOptions, len:number):number{
    return len * draft_options.draft_scale
}
//Перевод расстояния W --> V
export function Len_W2V(len:number, dp: IDraftPosition):number{
    return len / dp.wnd_world_scale
}
//Перевод расстояния V --> W
export function Len_V2W(len:number, dp: IDraftPosition):number{
    return len * dp.wnd_world_scale
}
//Перевод расстояния V --> D (переводить будем по цепочке)
export function Len_V2D(draft_options: IDraftOptions, len:number, dp: IDraftPosition):number{
    return Len_W2D(draft_options, Len_V2W(len, dp))
}
//Перевод расстояния D --> V (переводить будем по цепочке)
export function Len_D2V(draft_options: IDraftOptions, len:number, dp: IDraftPosition){
    return Len_W2V(Len_D2W(draft_options, len), dp)
}
/*
* Расчёт текущего масштаба в пикселях. 
* Просто протаскиваем отрезок длиной в 1 м
* в преобразование порта просмотра
*/
export function GetCurScale(dp: IDraftPosition):number{
    return Len_W2V(1.0, dp)
}

//масштабировать порт просмотра относительно точки
export function ScaleViewPort(draft_options: IDraftOptions, prev_pos: IDraftPosition, 
    pos:ICoords, vs: ICoords,
    scale_factor:number): IDraftPosition{
   let res = {...prev_pos}
   if((pos.x === 0 && pos.y === 0) || (pos.x == null && pos.y == null))
   {
       /*
       * масштабирование завязано на
       * передвижение левого верхнего угла
       * если же пытаются масштабировать
       * ровно относительно этой точки, то
       * расчёт не работает. смысла делать
       * отдельный случай ради этого не вижу
       *
       * также pos.x и pos.y могут быть равны
       * null для мобильной версии. в этом случае
       * просто не делаем масштабирование
       * для мобильной версии есть обработка
       * других событий
       */
       return res;
   }

   //переводим положение масштабирования в мировую СК
   let Zc = Pos_V2W(pos, prev_pos, vs)
   //оно будет неподвижной точкой
   let Uc = pos;
   /*
   * это положение нового верхнего левого
   * угла порта просмотра в мировой СК
   */
   let V1_l2: ICoords ={
       x: pos.x * (1.0 - scale_factor),
       y: pos.y * (1.0 - scale_factor)
   }
   V1_l2 = Pos_V2W(V1_l2, prev_pos, vs)
   /*
   * вывод формулы для положение 0 мировой СК
   * в новом порте просмотра переносить
   * из тетрадки сюда не буду
   * 
   * общая идея: преобразование координат - это
   * линейное преобразование точки в точку. прежнее
   * преобразование известно точно, новое, после масштабирования,
   * нужно найти. про него известно что точку Uc
   * оно переводит в то же место, что и прошлое, а 
   * новый угол координат V1_l2 переводит в (0,0)
   * по двум точкам можно найти всё преобразование.
   * но всё преобразование не нужно, достаточно
   * определить куда оно переводит (0, 0)
   * мировой СК
   */
   let V2_0W: ICoords = {
       x: V1_l2.x / (V1_l2.x - Zc.x) * Uc.x,
       y: V1_l2.y / (V1_l2.y - Zc.y) * Uc.y
   }
   /*
   * V2_0W - положение 0 относительно начала новой оконной СК
   * в результат нужно отдать это положение относительно
   * центра окна. пересчёт достаточно нехитрый
   * даже масштаб учитывать не надо
   */  
   res.wnd_world_scale *= scale_factor 
   res.wnd_world_zero = {
       x: V2_0W.x - (vs.x / 2.0),
       y: V2_0W.y - (vs.y / 2.0)
   } 
   /*
   * считаем новый масштаб, и если в пределы
   * не укладываемся, то возвращаем старое
   * преобразование
   */
   let new_scale = GetCurScale(res);
   if(new_scale > draft_options.draft_max_scale ||
       new_scale < draft_options.draft_min_scale){
           return prev_pos
       }
   return res;   
}

//функция определяет, целиком ли порт просмотра находится внутри карты
export function isViewportInsideMap(wnd_map:IRect, vp_size_px:ICoords):boolean
{
    /*
    * все координаты в оконной СК
    * порт просмотра лежит внутри карты в случае
    * когда её левый верхний угол имеет отрицательные координаты, 
    * а правый нижний угол имеет значения координат
    * больше ширины и высоты порта просмотра
    */
    return (wnd_map.lu.x < 0 && wnd_map.lu.y < 0
        && wnd_map.rd.x > vp_size_px.x
        && wnd_map.rd.y > vp_size_px.y) 
}

//функция определяет, целиком ли карта умещается в порт просмотра
export function isViewportOustideMap(vp:IViewPort, vp_size_px:ICoords):boolean
{
    /*
    * функция прямо противоположная isViewportInsideMap
    * определяет, все ли углы карты
    * вмещаются в текущий порт просмотра
    */ 
    return (vp.wnd_lu_pos.x > 0 && vp.wnd_lu_pos.y > 0
        && vp.wnd_rd_pos.x < vp_size_px.x
        && vp.wnd_rd_pos.y < vp_size_px.y) 
}

export interface IMapFragment
{
    //положение фрагмента относительно родителя (всей карты)
    left:number,
    top:number,
    width:number,
    height:number,
    //mipmap-уровень (для выбора изображения)
    mipmap_level:number
    //координаты ячейки
    cell_pos: ICoords
    /*
    * строковый путь по уровню карты
    * Q W - левый верхний и правый верхний
    * A S - левый нижний и правый нижний
    * 0 - верхний уровень
    */
    mipmap_path: string 
}

//функция проверяет, попадает ли точка в отрезок
// function isPointInRange(x:number, p1: number, p2:number)
// {
//     //на всякий случай упорядочиваем концы отрезка
//     var st = p1
//     var en = p2
//     if(st > en)
//     {
//         st = p2
//         en = p1
//     }
//     return (x >= st) && (x <= en)
// }

// //функция показывает, пересекаются ли отрезки (лежит ли первый во втором)
// function isRangeCrossing(r1s:number, r1e:number, r2s:number, r2e:number)
// {
//     return isPointInRange(r1s, r2s, r2e) || isPointInRange(r1e, r2s, r2e)
// }

/*
* функция строит путь до mipmap-фрагмента
* вида 0:0-LU-RD ...
*/
function buildMipmapPath(map_rect:IRect, cell_center:ICoords, target_level:number, mipmap:IMipMapParams)
{
    var map_width = map_rect.rd.x - map_rect.lu.x
    var res:string = ""
    for(var l:number = 0; l <= target_level; l++)
    {
        //размер ячейки на каждом уровне свой
        var m_count = mipmap.mipmap_zero_level_m * Math.pow(2, l)
        var cell_size = map_width / m_count
        if(l === 0)
        {
            //на нулевом уровне расчёт такой
            var x = Math.floor(cell_center.x / cell_size)
            var y = Math.floor(cell_center.y / cell_size)
            res = res + x + ":" + y
        }
        else
        {
            /*
            * идея следующая - посчитаем параметры ячейки
            * верхнего уровня и посмотрим в какой
            * квадрант попадает центр текущей
            */
            var m_count_upper = mipmap.mipmap_zero_level_m * Math.pow(2, l - 1)
            var cell_size_upper = map_width / m_count_upper

            var x_upper = Math.floor(cell_center.x / cell_size_upper)
            var y_upper = Math.floor(cell_center.y / cell_size_upper)

            var upper_center:ICoords = {
                x: x_upper * cell_size_upper + (cell_size_upper / 2.0),
                y: y_upper * cell_size_upper + (cell_size_upper / 2.0)
            }
            if(cell_center.y > upper_center.y )
            {
                //A или S
                res = res + "-" + (cell_center.x > upper_center.x ? "S:S":"A:A")
            } 
            else
            {
                //Q или W
                res = res + "-" + (cell_center.x > upper_center.x ? "W:W":"Q:Q")
            }               
        }
    }
    return res
}

/*
* функция принимает решение какой mipmap слой карты использовать
* на вход:
* - прямоугольник с картой, пересчитанный в СК порта просмотра
* - размеры порта просмотра
* - данные о mipmap-разбиении карты
*/
export function viewportSubdivision(wnd_map:IRect, 
    wnd_viweport:IRect, mipmap:IMipMapParams): IMapFragment[]
{
    var map_width:number = wnd_map.rd.x - wnd_map.lu.x
    var map_height:number = wnd_map.rd.y - wnd_map.lu.y

    /*
    * значит есть какой-то порт просмотра, который
    * в общем случае пересекается с контейнером карты
    * нужно определить, какой уровень mipmap
    * взять, в том числе 0
    * 
    * общий подход - уровень должен обеспечивать 
    * использование не более двух фрагментов
    * по максимальному размеру из ширины и высоты
    * в худшем случае - 0 уровень (m*n)
    */
    
    var gen_vp_size:number = wnd_viweport.rd.x >= wnd_viweport.rd.y 
        ? wnd_viweport.rd.x : wnd_viweport.rd.y
    var level0_grid_size = wnd_viweport.rd.x >= wnd_viweport.rd.y 
        ? mipmap.mipmap_zero_level_m : mipmap.mipmap_zero_level_n
    var gen_map_size = wnd_viweport.rd.x >= wnd_viweport.rd.y 
        ? map_width : map_height

    var fragment_width:number[] = []
    for(var l1:number = 0; l1 <= mipmap.mipmap_max_level; l1++)
    {
        if(l1 === 0)
        {
            //на уровне 0 это ширина фрагмента 0 уровня
            fragment_width[l1] = gen_map_size / level0_grid_size
        }
        else
        {
            //в два раза меньше чем прошлый
            fragment_width[l1] = fragment_width[l1 - 1] / 2.0
        }
    }
    //перебор не будет слишком глубоким
    var target_level:number = 0
    for(var l2:number = mipmap.mipmap_max_level; l2 >= 0; l2--)
    {
        if(fragment_width[l2] * 2.0 >= gen_vp_size)
        {
            //нашли уровень, который покрывает не более чем 2 фрагментами по размеру
            target_level = l2
            break
        }
    }
    //с целевым уровнем определились. теперь делаем разбиение
    var res:IMapFragment[] = []
    //ширина одного фрагмента 
    var m_count = mipmap.mipmap_zero_level_m * Math.pow(2, target_level)
    var n_count = mipmap.mipmap_zero_level_n * Math.pow(2, target_level)
    var cell_size = map_width / m_count

    /*
    * Выбрасывать невидимые на карте фрагменты будем достаточн примитивным спосбом
    * (пока во всяком случае) Пересчитаем их положение в СК порта просмотра
    * если фрагмент за пределами порта просмотра, пропускаем 
    */
    for(var m:number=0; m < m_count; m++)
    {
        for(var n:number=0; n < n_count;n++)
        {
            var cell_rect:IRect = {
                lu: {
                    x: wnd_map.lu.x + m * cell_size,
                    y: wnd_map.lu.y + n * cell_size,
                },
                rd: {
                    x: wnd_map.lu.x + (m + 1) * cell_size,
                    y: wnd_map.lu.y + (n + 1) * cell_size
                }
            }
            if(
                (cell_rect.rd.x <= wnd_viweport.lu.x) ||
                (cell_rect.lu.x >= wnd_viweport.rd.x) ||
                (cell_rect.rd.y <= wnd_viweport.lu.y) ||
                (cell_rect.lu.y >= wnd_viweport.rd.y)
            )
            {
                // точно за пределами порта просмотра, пропускаем
                continue
            }
            res.push(
                {
                    left: m * cell_size,
                    top: n * cell_size,
                    width: cell_size,
                    cell_pos: {x: m, y: n},
                    height: cell_size,
                    mipmap_level: target_level,
                    mipmap_path: buildMipmapPath(wnd_map,
                        {x:m * cell_size + (cell_size / 2),
                            y:n * cell_size + (cell_size / 2)},
                            target_level, mipmap)
                }
            )
        }
    }
    return res   
}

//подразумевается что координаты в пикселях, lu < rd
// function IsRectInsideRect(inner:IRect, outer:IRect):boolean
// {
//     return (
//         (inner.lu.x >= outer.lu.x) && (inner.lu.y >= outer.lu.y) &&
//         (inner.rd.x <= outer.rd.x) && (inner.rd.y <= outer.rd.y)
//     )
// }

export function MoveViewport(viewport_size_px: ICoords,
    draft_options: IDraftOptions,
    dp:IDraftPosition, shift:ICoords):IDraftPosition
{
    var res:IDraftPosition =  {
        wnd_world_zero:
        {
            x: dp.wnd_world_zero.x + shift.x,
            y: dp.wnd_world_zero.y + shift.y
        },
        wnd_world_scale: dp.wnd_world_scale
    }
    /*
    * res - это новое положение порта просмотра
    * смотрим, не выводит ли оно центр порта просмотра
    * из видимой области карты. если да, оставляем прошлое положения
    */
    var viewportCenter: ICoords = {
        x: viewport_size_px.x / 2,
        y: viewport_size_px.y / 2
    }
    var visibleLU = Pos_D2V(draft_options, draft_options.draft_visible_lu, res, viewport_size_px)
    var visibleRD = Pos_D2V(draft_options, draft_options.draft_visible_rd, res, viewport_size_px)
    if(visibleLU.x > viewportCenter.x || visibleRD.x < viewportCenter.x ||
        visibleLU.y > viewportCenter.y || visibleRD.y < viewportCenter.y) return dp;
    //if(visibleLU.x > viewportCenter.x) return dp;
    return res
}
/*
* ограничение достаточно простое:
* 1. масштаб ограничен снизу и сверху
* 2. центр порта просмотра не выпускается за пределы карты
*/
export function FitViewport(dp:IDraftPosition, 
    vp_size:ICoords, 
    draft_options: IDraftOptions,
    scale_pos:ICoords|null):IDraftPosition
{
    var res = {...dp}
    /*
    * передвижение порта просмотра ограничено
    * процедурой масштабирования можно вывести порт
    * просмотра за пределы ограничения
    * если так, нужно исправить
    */ 
    var viewportCenter: ICoords = {
        x: vp_size.x / 2,
        y: vp_size.y / 2
    }
    var visibleLU = Pos_D2V(draft_options, draft_options.draft_visible_lu, res, vp_size)
    var visibleRD = Pos_D2V(draft_options, draft_options.draft_visible_rd, res, vp_size)
    // по умолчанию поправка нулевая. она рассчитывается в пикселях и требуется в пикселях
    var dx = 0
    var dy = 0
    if(visibleLU.x > viewportCenter.x)
    {
        dx = visibleLU.x - viewportCenter.x
    }
    else
    {
        if(visibleRD.x < viewportCenter.x)
        {
            dx = visibleRD.x - viewportCenter.x
        }
    }
    if(visibleLU.y > viewportCenter.y)
    {
        dy = visibleLU.y - viewportCenter.y
    }
    else
    {
        if(visibleRD.y < viewportCenter.y)
        {
            dy = visibleRD.y - viewportCenter.y 
        }
    }
    return {
        wnd_world_zero: {
            x: res.wnd_world_zero.x - dx,
            y: res.wnd_world_zero.y - dy
        },
        wnd_world_scale: res.wnd_world_scale
    }
    // return res
    // /*
    // * расчёт будет идти в СК порта просмотра
    // * пересчитаем в эту СК координаты углов прямоугольника, 
    // * который мы не должны выпускать за порт просмотра
    // */
    // var brd_limit_vp_rect = {
    //     lu: Pos_D2V(draft_options, draft_options.draft_limit.lu, dp, vp_size),
    //     rd: Pos_D2V(draft_options, draft_options.draft_limit.rd, dp, vp_size),
    // }
   
    // var limit_size_vp = {
    //     x: brd_limit_vp_rect.rd.x - brd_limit_vp_rect.lu.x,
    //     y: brd_limit_vp_rect.rd.y - brd_limit_vp_rect.lu.y
    // }

    // //сначала посмотрим, а надо ли что-то править
    // if(IsRectInsideRect( {
    //     lu: {x: 0, y: 0},
    //     rd: vp_size
    // }, brd_limit_vp_rect,))
    // {
    //     //порт просмотра целиком внутри карты. границ не видно. правки не требуются
    //     return dp
    // }
    // var res = dp
    // if(limit_size_vp.x <= vp_size.x || limit_size_vp.y <= vp_size.y)
    // {
    //     /*
    //     * двигать возможно некуда, придётся масштабировать
    //     * будем масштабировать относительно центра, а потом двигать
    //     * 
    //     * один из коэффициентов по x и y точно будет > 1 
    //     * на всякий случай возьмём чуть больше
    //     */
    //     var scale = Math.max(vp_size.x / limit_size_vp.x,
    //         vp_size.y / limit_size_vp.y) * 1.05
    //     res = ScaleViewPort(draft_options, dp,
    //         scale_pos ??
    //         {
    //             x: vp_size.x / 2,
    //             y: vp_size.y / 2
    //         }, vp_size, 1.0 / scale)
    //     //обновим размеры для следующего расчёта
    //     brd_limit_vp_rect = {
    //         lu: Pos_D2V(draft_options, draft_options.draft_limit.lu, res, vp_size),
    //         rd: Pos_D2V(draft_options, draft_options.draft_limit.rd, res, vp_size),
    //     }    
    //     limit_size_vp = {
    //         x: brd_limit_vp_rect.rd.x - brd_limit_vp_rect.lu.x,
    //         y: brd_limit_vp_rect.rd.y - brd_limit_vp_rect.lu.y
    //     }
    // }
    // /*
    // * если по ширине и высоте порт просмотра целиком уместится внутри карты
    // * т.е. можно спокойно двигать вправо и влево, просто подкрутим
    // * положение
    // */
    // if(limit_size_vp.x >= vp_size.x && limit_size_vp.y >= vp_size.y)
    // {
    //     //двигать надо по вертикали и по горизонтали
    //     var dx:number = 0
    //     var dy:number = 0
    //     if(brd_limit_vp_rect.lu.x >= 0)
    //     {
    //         //сдвиг влево (-)
    //         dx = -brd_limit_vp_rect.lu.x
    //     }
    //     else
    //     {
    //         if(brd_limit_vp_rect.rd.x <= vp_size.x)
    //         {
    //             //сдвиг вправо (+)
    //             dx = vp_size.x - brd_limit_vp_rect.rd.x 
    //         }
    //     }

    //     if(brd_limit_vp_rect.lu.y >= 0)
    //     {
    //         //сдвиг вверх (-)
    //         dy = -brd_limit_vp_rect.lu.y
    //     }
    //     else
    //     {
    //         if(brd_limit_vp_rect.rd.y <= vp_size.y)
    //         {
    //             //сдвиг вниз (+)
    //             dy = vp_size.y - brd_limit_vp_rect.rd.y 
    //         }
    //     }
    //     //получили поправку пересчитывать её не надо
    //     dp.wnd_world_zero.x += dx
    //     dp.wnd_world_zero.y += dy
    // }
    // return res
}