import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { configureStore } from '@reduxjs/toolkit'
import { mapObjectsApi } from './services/MapClientAPI'
import selectedMapObjectTypesReducer from './features/selectedObjectType'
import viewportStateReducer from './features/map_draft/viewport'
import displayModeReducer from './features/displayMode'

export const history = createBrowserHistory()


const rootReducer = combineReducers({
  //это для данных карты с бекенда
  [mapObjectsApi.reducerPath]: mapObjectsApi.reducer,
  //это включенные и выключенные типы объектов
  selectedMapObjectTypes: selectedMapObjectTypesReducer,
  //это положение порта просмотра
  viewportState: viewportStateReducer,
  //это выбранный день
  displayMode: displayModeReducer,
  //router
  router: connectRouter(history)
});

export const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware:(getDefaultMiddleware)=>
      getDefaultMiddleware()
      .concat(routerMiddleware(history))
      .concat(mapObjectsApi.middleware)
  })


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch