import { useDispatch, useSelector } from "react-redux"
import { eIconType } from "../features/common"
import { switchMenuMode } from "../features/displayMode"
import { useMapObjectTypesQuery, useMapSettingsQuery } from "../services/MapClientAPI"
import { getSpriteLink } from "../services/utils"
import { RootState } from "../store"
import MapCalendar from "./elements/Calendar"
import MapMarkerFilter from "./elements/MapMarkerFilter"
import ModeSelectMobile from "./elements/ModeSelectMobile"


const MapMenuMobile = () =>{
    const {/* data, error,*/ isLoading} = useMapObjectTypesQuery()
    const {data:dataLang, /*error,*/ isLoading: isLoadingLang} = useMapSettingsQuery()
    const dispatch = useDispatch()
    //const disabled_types = useSelector((state: RootState) => state.selectedMapObjectTypes.disabled_ids)
    const displayMode = useSelector((state:RootState) => state.displayMode)

    if(isLoading || isLoadingLang){
        // здесь язык с сервера неизвестен, используем по умолчанию
        return (<div className='interactive-map__menu'></div>)
    }

    /*
    * если пользователь явно выбрал язык, используем его
    * иначе используем язык с сервера
    * 
    * определяем язык на уровне меню и передаём
    * потребителям в props.
    * Q: может переделать с помощью createAsyncThunk? 
    */
    var activeLang = displayMode.selected_lang
    if(activeLang === 'inv' && dataLang)
    {
        activeLang = dataLang?.lang
    }
    
    var hamBg = getSpriteLink(eIconType.MapMenuBg, 
        displayMode.menu_open ? 'ham_open': 'ham_closed')
    var resClassname = "interactive-map__menu menu-mobile" + 
        (displayMode.menu_open === true ? " ": " menu--closed")
    return <div className={resClassname}>
        <div className="header">
            <div className={"ham"} style={{
                backgroundImage:'url("' + hamBg  + '")'
            }} onClick={() => {
                dispatch(switchMenuMode(0))
            }}></div>
            <ModeSelectMobile activeLang={activeLang}/>
        </div>
        <div className={displayMode.menu_open === true ? "content": "content--closed"}>
        <MapCalendar selected_day={displayMode.selected_day} mobile_mode={true}/>
        <MapMarkerFilter activeLang={activeLang}/>
        </div>
    </div>
}

export default MapMenuMobile