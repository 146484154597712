import { eIconType } from "../../features/common"
import { IGameScenario } from "../../features/map_draft/api_entities"
import { useAllScenariosQuery } from "../../services/MapClientAPI"
import { getSpriteLink, printMultilangString } from "../../services/utils"
//import { RootState } from "../../store"
import { useDispatch } from 'react-redux'
import { setLanguage } from "../../features/displayMode"

export interface IModelSelectProps
{
    activeLang: string
}

function ModeSelect({activeLang}:IModelSelectProps) {

    const {data, /*error,*/ isLoading} = useAllScenariosQuery()
    
    const dispatch = useDispatch()
     
    if(isLoading){
        return (<div></div>)
    }
  
    return <div className="interactive-map__mode">
            <div className={activeLang==='ru' ? 'maplang--active': 'maplang'}
            onClick={() => dispatch(setLanguage('ru'))}>
                <img src={getSpriteLink(eIconType.MapMenuBg,'lang_ru')} alt="lang"/>
            </div>
            <div className={activeLang==='en' ? 'maplang--active': 'maplang'}
            onClick={() => dispatch(setLanguage('en'))}>
                <img src={getSpriteLink(eIconType.MapMenuBg,'lang_en')} alt="lang"/>
            </div>
            {data?.map((item:IGameScenario)=>{
                return(<div className={item.id === 1 ? 'scenario-item--active':'scenario-item'} 
                key={item.id}>{printMultilangString(item.title, activeLang)}</div>)
            })}
    </div>
}

export default ModeSelect