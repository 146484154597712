import { ICoords, IDraftOptions, IDraftPosition, IRect, IViewPort } from "../../features/map_draft/geometry"
import { Pos_D2V } from "../../services/math"
//import DebugOverlay from "./DebugOverlay"

export interface IMapBorderVectorProps
{
    vp:IViewPort, 
    viewport_size_px: ICoords,
    draft_options: IDraftOptions,
    draft_pos: IDraftPosition
}

/*
* поля с префиксом clip
* нужны для проверки видимости фрагментов
* поля без префикса не подходят, т.к.
* они не учитывают поворот элемента (transform)
*/
interface IBorderElemParam
{
    clip_left: number,
    clip_top: number,
    clip_width: number,
    clip_height: number,
    left: number,
    top: number,
    width: number,
    height: number,
    bgUrl: string,
    bgOrient: number, //0 - LU, 1 - RU, 2 - RD, 3 - LD, 10 - L , 20 - U, 30 - R, 40 - D
    bgPos: number// 0 - reg, 1 - start, 2 - end
}

function isFragmentVisible(fragment:IBorderElemParam, border_block_pos: ICoords, viewport_size_px:ICoords)
{
    //посчитаем координаты углов
    var lu = {
        x: fragment.clip_left + border_block_pos.x, 
        y: fragment.clip_top + border_block_pos.y
    }
    var rd = {
        x: lu.x + fragment.clip_width, 
        y: lu.y + fragment.clip_height
    }
    //теперь условия видимости
    if((rd.x < 0) || (lu.x > viewport_size_px.x)) return false;
    if((rd.y < 0) || (lu.y > viewport_size_px.y)) return false;
    return true
}

const MapBorderVector = ({vp, viewport_size_px, draft_options,
    draft_pos}:IMapBorderVectorProps) =>{

    /*
    * основная часть стилей аналогична контейнеру карты
    * TODO вытащить в отдельную функцию
    */

    var visibleLU = Pos_D2V(draft_options, draft_options.draft_visible_lu, draft_pos, viewport_size_px)
    var visibleRD = Pos_D2V(draft_options, draft_options.draft_visible_rd, draft_pos, viewport_size_px)

    /*
    * теперь рамка карты
    * нужно определиться с размером в пикселях для текущего масштаба
    * ширина рамки определяется соотношением ширины и высоты
    * файлов svп с ресурсами (константа 3.883)
    */
    var mm = draft_options.draft_mipmap

    var wnd_map:IRect = {
        lu: vp.cnt_lu_pos,
        rd: vp.cnt_rd_pos
    }

    var map_width:number = wnd_map.rd.x - wnd_map.lu.x
    var m_count = mm.mipmap_zero_level_m * Math.pow(2, mm.mipmap_max_level)
    var cellSize = map_width / m_count

    var borderWidthPx = cellSize / 3.833
    /*
    * контейнер для рамки - видимая часть карты с отступами
    * требуемой ширины
    */

    var visibleSize:ICoords = {
        x: visibleRD.x - visibleLU.x,
        y: visibleRD.y - visibleLU.y
    }
    var brdContainer = [
        visibleLU.x - borderWidthPx,
        visibleLU.y - borderWidthPx,
        visibleSize.x + borderWidthPx * 2,
        visibleSize.y + borderWidthPx * 2
    ]
    
    var map_border_style={
        left: (brdContainer[0]) + 'px',
        top: (brdContainer[1]) + 'px',
        width: (brdContainer[2]) + 'px',
        height: (brdContainer[3]) + 'px'
    }

    var borderElements:Array<IBorderElemParam> = []
    //с углами достаточно просто
    borderElements.push(
        {
            clip_left: 0,
            clip_top: 0,
            clip_width: borderWidthPx,
            clip_height:borderWidthPx,
            left: 0,
            top: 0,
            width: borderWidthPx,
            height:borderWidthPx,
            bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_corner_u.svg',
            bgOrient: 0,
            bgPos: 0
        },
        {
            clip_left: visibleSize.x + borderWidthPx,
            clip_top: 0,
            clip_width: borderWidthPx,
            clip_height:borderWidthPx,
            left: visibleSize.x + borderWidthPx,
            top: 0,
            width: borderWidthPx,
            height:borderWidthPx,
            bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_corner_u.svg',
            bgOrient: 1,
            bgPos: 0
        },
        {
            clip_left: visibleSize.x + borderWidthPx,
            clip_top: visibleSize.y + borderWidthPx,
            clip_width: borderWidthPx,
            clip_height:borderWidthPx,
            left: visibleSize.x + borderWidthPx,
            top: visibleSize.y + borderWidthPx,
            width: borderWidthPx,
            height:borderWidthPx,
            bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_corner_u.svg',
            bgOrient: 2,
            bgPos: 0
        },
        {
            clip_left: 0,
            clip_top: visibleSize.y + borderWidthPx,
            clip_width: borderWidthPx,
            clip_height:borderWidthPx,
            left: 0,
            top: visibleSize.y + borderWidthPx,
            width: borderWidthPx,
            height:borderWidthPx,
            bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_corner_u.svg',
            bgOrient: 3,
            bgPos: 0
        }
    )

    /*
    * с линиями несколько сложнее. ресурсы рассчитаны на целое количество
    * клеток максимальной детализацией. видимая область карты не укладывается
    * в целое число клеток. в начале линии и в конце добавляется два элемента, 
    * которые гарантированно стыкуются с углами. такие элементы всегда 
    * занимают меньше половины элемента линии
    */
    var verSteps = Math.floor(visibleSize.y / cellSize)
    var verRest = visibleSize.y - verSteps * cellSize

    var horSteps = Math.floor(visibleSize.x / cellSize)
    var horRest = visibleSize.x - horSteps * cellSize

    // var debugLines:Array<string> = [
    //     'verSteps: ' + verSteps,
    //     'verRest: ' + verRest,
    //     'horSteps: ' + horSteps,
    //     'horRest: ' + horRest,
    //     'borderSize: ' + borderWidthPx
    // ]
    var fragmentIndex = 0
    var fragmentCount = 4
    //целые части
    var topPadding = verRest / 2
    var leftPadding = horRest / 2
    for(var s = 0; s < verSteps; s++)
    {
        //отступ сверху. неполные элементы в начале списка и в конце 
        borderElements.push({
            clip_left: 0,
            clip_top: topPadding + s * cellSize + borderWidthPx,
            clip_width: borderWidthPx,
            clip_height: cellSize,
            left: 0,
            top: topPadding + s * cellSize + borderWidthPx,
            width: borderWidthPx,
            height: cellSize + 1,
            bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_0' + ((fragmentIndex % fragmentCount) + 1) + '_v.svg',
            bgOrient: 10,
            bgPos: 0
        })
        borderElements.push({
            clip_left: visibleSize.x + borderWidthPx,
            clip_top: topPadding + s * cellSize + borderWidthPx,
            clip_width: borderWidthPx,
            clip_height: cellSize,
            left: visibleSize.x + borderWidthPx,
            top: topPadding + s * cellSize + borderWidthPx,
            width: borderWidthPx,
            height: cellSize + 1,
            bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_0' + ((fragmentIndex % fragmentCount) + 1) + '_v.svg',
            bgOrient: 30,
            bgPos: 0
        })
        fragmentIndex++
    }
    //хвосты линий
    borderElements.push({
        clip_left: 0,
        clip_top: borderWidthPx,
        clip_width: borderWidthPx,
        clip_height: topPadding,
        left: 0,
        top: borderWidthPx,
        width: borderWidthPx,
        height: topPadding  + 1,
        bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_start_v.svg',
        bgOrient: 10,
        bgPos: 1
    })
    borderElements.push({
        clip_left: 0,
        clip_top: topPadding + verSteps * cellSize + borderWidthPx,
        clip_width: borderWidthPx,
        clip_height: topPadding,
        left: 0,
        top: topPadding + verSteps * cellSize + borderWidthPx,
        width: borderWidthPx,
        height: topPadding + 1,
        bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_end_v.svg',
        bgOrient: 10,
        bgPos: 2
    })

    borderElements.push({
        clip_left: visibleSize.x + borderWidthPx,
        clip_top: borderWidthPx,
        clip_width: borderWidthPx,
        clip_height: topPadding,
        left: visibleSize.x + borderWidthPx,
        top: borderWidthPx,
        width: borderWidthPx,
        height: topPadding + 1,
        bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_start_v.svg',
        bgOrient: 30,
        bgPos: 1
    })
    borderElements.push({
        clip_left: visibleSize.x + borderWidthPx,
        clip_top: topPadding + verSteps * cellSize + borderWidthPx,
        clip_width: borderWidthPx,
        clip_height: topPadding,
        left: visibleSize.x + borderWidthPx,
        top: topPadding + verSteps * cellSize + borderWidthPx,
        width: borderWidthPx,
        height: topPadding + 1,
        bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_end_v.svg',
        bgOrient: 30,
        bgPos: 2
    })

    //теперь горизонталь
    for(var s1 = 0; s1 < horSteps; s1++)
    {
        //здесь есть разница между параметрами clip и без
        borderElements.push({
            clip_left: borderWidthPx + leftPadding + s1 * cellSize,
            clip_top: 0,
            clip_width: cellSize,
            clip_height: borderWidthPx, 
            left: leftPadding + s1 * cellSize + borderWidthPx,
            top: 0,
            width: cellSize + 1,
            height: borderWidthPx, 
            bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_0' + ((fragmentIndex % fragmentCount) + 1) + '_h.svg',
            bgOrient: 10,
            bgPos: 0
        })
        borderElements.push({
            clip_left: borderWidthPx + leftPadding + s1 * cellSize,
            clip_top: borderWidthPx + visibleSize.y,
            clip_width: cellSize,
            clip_height: borderWidthPx, 
            left: leftPadding + s1 * cellSize + borderWidthPx,
            top: borderWidthPx + visibleSize.y,
            width: cellSize + 1,
            height: borderWidthPx, 
            bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_0' + ((fragmentIndex % fragmentCount) + 1) + '_h.svg',
            bgOrient: 30,
            bgPos: 0
        })
        fragmentIndex++
    }

    borderElements.push({
        clip_left: borderWidthPx,
        clip_top: 0,
        clip_width: leftPadding,
        clip_height: borderWidthPx,
        left: borderWidthPx,
        top: 0,
        width: leftPadding + 1,
        height: borderWidthPx,
        bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_start_h.svg',
        bgOrient: 10,
        bgPos: 1
    })

    borderElements.push({
        clip_left: borderWidthPx + leftPadding + horSteps * cellSize,
        clip_top: 0,
        clip_width: leftPadding,
        clip_height: borderWidthPx,
        left: visibleSize.x + borderWidthPx - leftPadding,
        top: 0,
        width: leftPadding + 1,
        height: borderWidthPx,
        bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_end_h.svg',
        bgOrient: 10,
        bgPos: 2
    })

    borderElements.push({
        clip_left: borderWidthPx,
        clip_top: borderWidthPx + visibleSize.y,
        clip_width: leftPadding,
        clip_height: borderWidthPx,
        left: borderWidthPx,
        top: borderWidthPx + visibleSize.y,
        width: leftPadding  + 1,
        height: borderWidthPx,
        bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_start_h.svg',
        bgOrient: 30,
        bgPos: 1
     })

    borderElements.push({
        clip_left: borderWidthPx + leftPadding + horSteps * cellSize,
        clip_top: borderWidthPx + visibleSize.y,
        clip_width: leftPadding,
        clip_height: borderWidthPx,
        left: borderWidthPx + leftPadding + horSteps * cellSize,
        top: borderWidthPx + visibleSize.y,
        width: leftPadding + 1,
        height: borderWidthPx,
        bgUrl: process.env.REACT_APP_MAP_FRAGMENT_ROOT + 'maps/1/border/map_brd_line_end_h.svg',
        bgOrient: 30,
        bgPos: 2
    })
    

    return <div className="mapborder" style={map_border_style}>
        {/* <DebugOverlay lines={debugLines}/> */}
       {borderElements.filter((item) => {
        return isFragmentVisible(item, {
            x: brdContainer[0],
            y: brdContainer[1]
        }, viewport_size_px)
       }).map((item, index) => {
            var rotationAngle = 0
            switch(item.bgOrient)
            {
                case 1:
                    rotationAngle = 90
                    break;
                case 2:
                    rotationAngle = 180
                    break;
                case 3:
                    rotationAngle = 270
                    break;
                case 20:
                    rotationAngle = 90
                    break;
                case 30:
                    rotationAngle = 180
                    break;
                case 40:
                    rotationAngle = 270
                    break;
            }

            var elementClass = "internal--border--corner"
            if(item.bgOrient >= 10)
            {
                elementClass = "internal--border--line"
            }
            var bgPos = 'letf top'
            if(item.bgPos === 2)
            {
                bgPos = 'left bottom' 
            }
            var borderStyle = {
                left: (item.left) + 'px',
                top: (item.top) + 'px',
                width: (item.width) + 'px',
                height: (item.height) + 'px',
                backgroundImage: 'url("' + item.bgUrl + '")',
                transform: 'rotate(' + rotationAngle + 'deg)',
                backgroundPosition: bgPos
            }
            return (<div className={elementClass} style={borderStyle} key={index}></div>)
       })}
       
        {/* {borderElements.filter((item) => {
        return isFragmentVisible(item, {
            x: brdContainer[0],
            y: brdContainer[1]
        }, viewport_size_px)
       }).map((item, index) => {
        var borderStyle = {
            left: (item.clip_left) + 'px',
            top: (item.clip_top) + 'px',
            width: (item.clip_width) + 'px',
            height: (item.clip_height) + 'px',
        }
            return <div className="internal--border--clip" style={borderStyle} key={index * 1000}></div>
       })} */}
    </div>
}

export default MapBorderVector