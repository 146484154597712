import { eIconType } from "../../features/common"
import { IMapMarker } from "../../features/mapMarker"
import { ICoords, IDraftPosition } from "../../features/map_draft/geometry"
import { Pos_W2V } from "../../services/math"
import { getSpriteLink } from "../../services/utils"

export interface IMapMarkerProps
{
    item:IMapMarker, 
    draft_position: IDraftPosition,
    viewport_size: ICoords
}

const MapMarker = ({item, draft_position, viewport_size}:IMapMarkerProps) => {
    var view_pos = Pos_W2V(item.world_pos, draft_position, viewport_size)
    return  (<div className="marker" style={{
        left: view_pos.x +'px', 
        top:view_pos.y +'px'}}>
        <img className="marker__icon" alt="marker"
        src={getSpriteLink(eIconType.MapMarker, item.sprite_id)}/>
    </div>)
}

export default MapMarker