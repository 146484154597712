import { eIconType } from "../../features/common"
import { FormatDayNumber, getSpriteLink } from "../../services/utils"
import { useDispatch } from 'react-redux'
import { switchSelectedDay } from "../../features/displayMode"

export interface IMapCalendarProps{
    selected_day:number,
    mobile_mode: boolean
}

const MapCalendar = ({selected_day, mobile_mode}:IMapCalendarProps) =>{
    const dispatch = useDispatch()
    var calendarClassname = "interactive-map__menu--calendar"
    return <div className={calendarClassname}>
        <div className="interactive-map__menu--calendar-header">
            <p style={{
                backgroundImage: 'url("' + getSpriteLink(eIconType.MapMenuBg, 'calendar_holes') + '")'
            }}></p>
            <p style={{
                backgroundImage: 'url("' + getSpriteLink(eIconType.MapMenuBg, 'calendar_holes') + '")'
            }}></p>
            <p style={{
                backgroundImage: 'url("' + getSpriteLink(eIconType.MapMenuBg, 'calendar_holes') + '")'
            }}></p>
        </div>
        <div className={"row" + (mobile_mode ? " mobile": "")}>
        {Array.from(Array(6).keys()).map(x => x + 1).map((item, index) =>{
            return(<p className="day" key={item} style={{
                backgroundImage:'url("' + getSpriteLink(eIconType.MapMenuBg, 'day_' + FormatDayNumber(item)) + (selected_day === item ? '_a': '') + '")'
            }} onClick={() =>{
                dispatch(switchSelectedDay(item))
            }}>{}</p>) 
        })}
        </div>
        <div className={"row" + (mobile_mode ? " mobile": "")}>
        {Array.from(Array(6).keys()).map(x => x + 1).map((item, index) =>{
            return(<p className="day" key={item} style={{
                backgroundImage:'url("' + getSpriteLink(eIconType.MapMenuBg, 'day_' + FormatDayNumber(item + 6)) + (selected_day === (item + 6) ? '_a': '') +  '")'
            }} onClick={() =>{
                dispatch(switchSelectedDay(item + 6))
            }}></p>)
        })}
        </div>
    </div>
}

export default MapCalendar