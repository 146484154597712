import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISelectedMapObjectTypes {
    disabled_ids:  Number[]
}

const initialState = {
    disabled_ids: new Array<Number>(0)
}

export const selectedMapObjectTypes = createSlice({
    name: 'selectedObjectTypes',
    initialState,
    reducers:{
        // добавлять в список выключенных типов будем только если там такого ещё нет
        hideObjectType: (state, action: PayloadAction<Number>) =>{
            if(!state.disabled_ids.includes(action.payload)){
                state.disabled_ids.push(action.payload)
            }
        },
        // чтобы показать тип объекта, нужно убрать его из списка скрытых
        showObjectType: (state, action: PayloadAction<Number>) =>{
            state.disabled_ids = state.disabled_ids.filter((value) =>{
                return value !== action.payload
            })
        },
        // переключаем видимость. если есть, удалим. если нет, добавим
        switchObjectType: (state, action: PayloadAction<Number>) =>{
            if(!state.disabled_ids.includes(action.payload)){
                state.disabled_ids.push(action.payload)
            }else{
                state.disabled_ids = state.disabled_ids.filter((value) =>{
                    return value !== action.payload
                })
            }
        }
    }
})

export const { hideObjectType, showObjectType, switchObjectType} = selectedMapObjectTypes.actions
export default selectedMapObjectTypes.reducer