import {eIconType, I18NString} from '../features/common'
import { IAPIContourPoint, IAPIMapContour, IMapContour } from '../features/map_draft/api_entities'
import svg_sprite from '../static/images/icons/svg/icon-sprite.svg'

/*
* получение ссылки на спрайт для <img src='...'/>
*/
export function getSpriteLink(iconType:eIconType, spriteName:string)
{
    return svg_sprite + '#icons--' + iconType + '--' + spriteName
}

/*
* преобразование многоязычной строки в строку на конкретном языке
*/
export function printMultilangString(str:I18NString, mode:string)
{
    if(mode === 'ru')
    {
        return str.ru
    }
    else 
    {
        return str.en
    }
}

export function Num2Str(n:number,prec:number) {
    if(n==null) return '-'
    //let scale = Math.pow(10, prec)
    //return Math.round(n * scale) / scale
    return n.toFixed(prec)
}


export function FormatCoord(n:number):string
{
    return (n < 0 ? '-':' ') + Num2Str(Math.abs(n), 2).padStart(7, '0');
}

export function FormatCoordCont(n:number):string
{
    return (n < 0 ? '-':'') + Num2Str(Math.abs(n), 2).padStart(7, '0');
}

export function FormatDayNumber(n:number):string
{
    return Num2Str(Math.abs(n), 0).padStart(2, '0');
}

function getCurveType(letter:number):string
{
    switch(letter)
    {
        case 0: return "m"
        case 1: return "c"
        case 2: return "M"
        case 3: return "C"
    }
    return "?"
}

export function buildContourPath(apiRes:IAPIMapContour[]):IMapContour[]
{
    return apiRes.map((item:IAPIMapContour, index) =>{
        var path:string = ''
        var cur_type = ''
        for(var p = 0; p < item.contour_points.length; p++)
        {   
            var pt:IAPIContourPoint = item.contour_points[p];
            var local_type = getCurveType(pt.point_type)
            if (cur_type !== local_type)
            {
                path += local_type
                path += ' '
                cur_type = local_type
            }
            path += pt.world_x
            path += ','
            path += pt.world_y
            path += ' '
        }
        path += 'z'
        return {
            world_x: item.world_x,
            world_y: item.world_y,
            size_x: item.size_x,
            size_y: item.size_y,
            svg_width: item.svg_width,
            svg_height: item.svg_height,
            contour_kind: item.contour_kind,
            contour_type: item.contour_type,
            contour_path: path,
            contour_day: item.contour_day,
            always_visible: item.always_visible
        }
    })
}